

import { useState, isValidElement } from "react";
import ModalCustom from "../../../../Modal/ModalCustom";
import Toast from "../../../../Toast/Toast";
import services from "../../../../../store/services";
import FieldLabelAndIcon from "../FieldLabelAndIcon";
import KeywordSynonyms from "./KeywordSynonyms";

const KeywordsField = ({
    reqType,
    inputType, 
    formName,
    fields,
    updateFormState,
    readOnlyMode,
    formPillsStyle,
    formPillsBtnFontWeight,
    formValidations,
    currFormData,
    displayGrayPills,
    tooltipText,
    showLoadingSpinner,
    clipboardHandler,
    synonymsServiceRef}) => {


  // this component adds and removes keywords on the parent form element
  // via updateFormState, and then uses that data to render the pills on the dom
  // it doesn't have a state with the keywords
  const [cssState, setCssState] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const [currSynonymsList, setCurrSynonymsList] = useState(null);
  const [allSynonymsList, setAllSynonymsList] = useState(null);

  const MAX_KEYWORDS_ALLOWED = 20;
  const urlCheckerService = services.urlCheckerService;
  const keywordInputElID = `${reqType}-${inputType}`;

  const isDarkMode = false;

  const changeCssForKwInput = (validationStatus, errorMsg) => {
    try {
      let kwInputEl = document.querySelector(keywordInputElID);
      if (validationStatus) {
        kwInputEl.classList.remove("is-invalid");
        kwInputEl.classList.add("is-valid");
      } else {
        kwInputEl.classList.remove("is-valid");
        kwInputEl.classList.add("is-invalid");
      }
      let kwInputFeedbackEl = document.querySelector("#kw-error-feedback");
      kwInputFeedbackEl.innerText = errorMsg;
    } catch (error) {}
  };

  const showSynonymsBox = (_showBox = null, _showSpinner=null )=>{

    if(_showBox !== null){
      let synSuggDiv = document.querySelector(".kw-synonyms-list-selector");
      synSuggDiv.style.visibility = _showBox ? "visible" : "hidden";
      _showBox ? synSuggDiv.classList.remove("change-kw-spinner-size") : synSuggDiv.classList.add("change-kw-spinner-size");
      let synSuggSpinnerEl = document.querySelector(".synonyms-kw-spinner-selector");
      synSuggSpinnerEl.style.visibility = "visible";
    }
    
    if(_showBox === null && _showSpinner !== null){
      let synSuggSpinnerEl = document.querySelector(".synonyms-kw-spinner-selector");
      synSuggSpinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";
    }
  }

  const isAlreadyAKeyword = (synonym) => {
    let keywordsArr = [...currFormData.keywordsObj.keywords.value];
    let keywordsForReviewArr = [...currFormData.keywordsObj.keywordsforreview.value];
    return keywordsArr.includes(synonym) || keywordsForReviewArr.includes(synonym);
  };
  const isAlreadyASynonym = (synonymsList, synonym) => {
    return synonymsList.includes(synonym);
  };

  const validateSynonyms =  async (synonyms) => {
    let validSynonyms = [];
    let currLocationsSelected = null;
    currLocationsSelected = [...currFormData.countries.value];
    let currRefinersSelected = null;
    currRefinersSelected = [...currFormData.refinerPages.value];

    let synonymsList = [];
    let suggBox = document.querySelector(".kw-synonyms-list-box-selector");
    let suggBoxChilds = suggBox.childNodes;
    for (let i = 0; i < suggBoxChilds.length; i++) {
      const suggChild = suggBoxChilds[i];
      let suggText = suggChild.childNodes[0].innerText;
      synonymsList.push(suggText);
    }

    try {
      var promises = [];
      for (let i = 0; i < synonyms.length; i++) {
        let isValid = formValidations.validateKeyword(synonyms[i], currLocationsSelected, currRefinersSelected);
        promises.push(isValid);
      }
       const results= await Promise.all(promises);
       results.forEach((isValid,i)=>{
          if (isValid.isValid && !isAlreadyAKeyword(synonyms[i]) && !isAlreadyASynonym(synonymsList, synonyms[i])) {
            validSynonyms.push(synonyms[i]);
          }
        })
      return validSynonyms;
    } catch (error) {
      if (error.code === "ERR_CANCELED") {
        console.log("Synonyms validation was interrupted");
      } else {
        console.log("Synonyms couldn't be validated");
        showSynonymsBox(false);
        if (synonymsList.length === 0) {
          showSynonymsBox(false);
        }
        throw error;
      }
    }
  };

  const differenceWithSets = (setA, setB)=> {
    let _difference = new Set(setA);
    for (let elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  const removeRelatedSynonyms = async(_synonymToRemove) => {
    let currAllSynList = [];
    if(allSynonymsList !== null){
      currAllSynList = [...allSynonymsList];
    }
    if(currAllSynList.includes(_synonymToRemove)){

      // remove synonym from the all list
      currAllSynList = currAllSynList.filter((el) => {
        if (el !== _synonymToRemove) {
          return el;
      }});

      // if list is empty close box and delete curr syns from list
      if(currAllSynList.length === 0){
        showSynonymsBox(false); //hide box
        showSynonymsBox(null, false);//hide spinner
        setCurrSynonymsList([]); // set empty array
      }else{
        showSynonymsBox(null, true); 
        // if list is not empty,
        // remove related synonyms from the current syn list
        const abortController = new AbortController();
        let synQuery = await synonymsServiceRef.GetSynonymsByKeywords(
          [_synonymToRemove],
          abortController
        );

        let synonymsOfCurrKw = [...synQuery.data];

        // create two sets and perform the difference operation between them
        // to remove the synonyms asociated with the keyword that has been removed
        let currSynSet = new Set([...currSynonymsList]);
        let relatedSynSet = new Set([...synonymsOfCurrKw]);
        let finalCurrSynSet = differenceWithSets(currSynSet,relatedSynSet);
        let finalCurrSynArr = Array.from(finalCurrSynSet);

        finalCurrSynArr = finalCurrSynArr.sort();
        setAllSynonymsList(currAllSynList);
        setCurrSynonymsList(finalCurrSynArr);

        showSynonymsBox(null, false); 
        
      }
    }

  }

  const fetchSynonyms = async (_lastSuggAdded) => {
    const abortController = new AbortController();
    let keywordsArr = [...currFormData.keywordsObj.keywords.value];
    let keywordsForReviewArr = [...currFormData.keywordsObj.keywordsforreview.value];
    let totalKeywords = keywordsArr.concat(keywordsForReviewArr);
    let synonyms = [];
    synonyms = await synonymsServiceRef.GetSynonymsByKeywords(
      totalKeywords,
      abortController
    );


    // check if added kw has synonyms for itself
    let synonymsOfCurrKw = await synonymsServiceRef.GetSynonymsByKeywords(
      [_lastSuggAdded],
      abortController
    );

    // if it does, add it to the "keywords that have synonyms list"
    if(synonymsOfCurrKw.data.length > 0){
      let currAllSynList = [];
      if(allSynonymsList !== null){
        currAllSynList = [...allSynonymsList];
      }
      currAllSynList.push((_lastSuggAdded));
      setAllSynonymsList(currAllSynList);
    }


    if(typeof(synonyms.data)==="string"){
      synonyms = JSON.parse(synonyms.data);
    } else {
      synonyms = synonyms.data;
    }

    if (synonyms.length === 0) {
      return synonyms;
    } else {
      showSynonymsBox(true);

      synonyms = synonyms.filter((synonym) => !totalKeywords.includes(synonym));
      let sortedSynonyms = synonyms.sort();
      const newSynonyms = await validateSynonyms(sortedSynonyms);
      
      if(currSynonymsList !== null && currSynonymsList.length >= 0){
        let currSynList = [...currSynonymsList];
        //add the new and the old ones
        let totalNewList = currSynList.concat(newSynonyms);
        // create list with no duplicates
        let synSet = new Set();
        for (let i = 0; i < totalNewList.length; i++) {
          const currSyn = totalNewList[i];
          if(currSyn !== _lastSuggAdded){
            synSet.add(currSyn);
          }
        }
        let finalSynList = Array.from(synSet);
        let sortedFinalList = finalSynList.sort();

        showSynonymsBox(null, false);
        setCurrSynonymsList(sortedFinalList);
      }else{
        showSynonymsBox(null, false);
        setCurrSynonymsList(newSynonyms);
      }
    }
  };

  const removeSynonymFromList = (_synToRemove)=>{
    let synSuggList = [...currSynonymsList];
    let filteredList = synSuggList.filter((el) => {
      if (el !== _synToRemove) {
        return el;
      }
    });
    if(filteredList.length === 0){
      //close sugg box if no synonyms are on the list
      showSynonymsBox(false); 
    }
    setCurrSynonymsList(filteredList);
  }

  const addKeywordInArray = async(valueToPush, currLocationsSelected,  currRefinersSelected)=>{

    // only use the validation method from the validation class of each form,
    // then add the keyword according to that result.
    // all validations classes should have a method with the same name,
    // parameters and return values for this case.
    let lowercaseKw = valueToPush.trim().toLowerCase();
    let validationResult = await formValidations.validateKeyword(lowercaseKw, currLocationsSelected, currRefinersSelected);

    let kwArr = {...currFormData.keywordsObj};

    if (!validationResult.isValid) {
        // red kw (invalid)
        if (
            kwArr.keywords.value.length === 0
        ) {
            setCssState(" is-invalid ");
            setStatusMessage(
            `Please enter at least one valid, relevant keyword in order to successfully submit this ${formName} request.`
            );
            updateFormState(fields.redKwField, valueToPush, false);
        } else if (
            kwArr.keywordsforreview.value.indexOf(valueToPush) === -1
        ) {
            updateFormState(fields.redKwField, valueToPush, true);
            changeCssForKwInput(true, "");
        } else if (
            kwArr.keywordsforreview.value.indexOf(valueToPush) >= 0
        ) {
            setModalMsg("You have already added this keyword.");
            setShowModal(true);
        }
    } else if (validationResult.isValid) {
        if (kwArr.keywords.value.length === 0) {
            setCssState(" is-valid ");
            updateFormState(fields.greenKwField, valueToPush, true);
            changeCssForKwInput(true, "");
        } else {
            updateFormState(fields.greenKwField, valueToPush, true);
            changeCssForKwInput(true, "");
        }
    }

    showLoadingSpinner(false);
    fetchSynonyms(valueToPush);
  }

  const addKeyword = async (event, isSynonymSugg = null) => {
    let elemRef = {};

    if(isSynonymSugg){
      elemRef = event.target;
      // remove element from suggestion list
      removeSynonymFromList(event.target.value);
    }else{
      elemRef = document.getElementById(keywordInputElID);
    }

    let isEnterKey = event.code === "Enter";
    let isClick = event?.type === "click";

    if(isEnterKey || isClick){
      if(currFormData.countries.value.length > 0 &&
        currFormData.refinerPages.value.length > 0
      ){
          showLoadingSpinner(true);
      }
  
      let regex = urlCheckerService.textRegex;
      if (elemRef.value.match(regex)) {
        setCssState(" is-invalid ");
        setStatusMessage('Please do not include the special characters \\ and "');
      } else {
        setCssState("");
        setStatusMessage("");
      }
  
      if (event.key === "Enter" || event.type === "click" || isSynonymSugg) {
        event.preventDefault();
        let keywordsList = elemRef.value.split(",");
        let regex = urlCheckerService.textRegex;
  
        if (elemRef.value.match(regex)) {
          setModalMsg('Please do not include the special characters \\ and "');
          setShowModal(true);
          setStatusMessage("");
          setCssState("");
          elemRef.value = "";
          return;
        }
  
        let greenLen = currFormData.keywordsObj.keywords.value.length;
        let redLen = currFormData.keywordsObj.keywordsforreview.value.length;
  
        let totalKwsSelected = parseInt(greenLen) + parseInt(redLen);
        if (totalKwsSelected >= MAX_KEYWORDS_ALLOWED) {
          setModalMsg(`Only ${MAX_KEYWORDS_ALLOWED} keywords are allowed.`);
          setShowModal(true);
          showLoadingSpinner(false);
          return;
        }
  
        if (keywordsList.length > 0) {
  
          let currLocationsSelected = null;
          let currRefinersSelected = null;
  
          if(currFormData.countries.hasOwnProperty("value")){
              currLocationsSelected = [...currFormData.countries.value];
          }
          if(currFormData.refinerPages.hasOwnProperty("value")){
              currRefinersSelected = [...currFormData.refinerPages.value];
          }
  
          if (
            currRefinersSelected.length === 0 ||
            currLocationsSelected.length === 0
          ) {
            elemRef.value = "";
            setModalMsg("Please select a Location and a Refiner first.");
            setShowModal(true);
            return;
          }
  
          for (let i = 0; i < keywordsList.length; i++) {
            let valueToPush = keywordsList[i];
            let lowercaseKw = valueToPush.trim().toLowerCase();
            let upperCaseKw = valueToPush.trim().toUpperCase();
  
            let greenKwRef = currFormData.keywordsObj.keywords.value;
            let redKwRef = currFormData.keywordsObj.keywordsforreview.value;
  
            let isNotInGreenKwList = greenKwRef.indexOf(valueToPush) === -1 &&
            greenKwRef.indexOf(lowercaseKw) === -1 &&
            greenKwRef.indexOf(upperCaseKw) === -1;
            
            let isNotInRedKwList = redKwRef.indexOf(valueToPush) === -1 &&
            redKwRef.indexOf(lowercaseKw) === -1 &&
            redKwRef.indexOf(upperCaseKw) === -1;
  
            if (isNotInGreenKwList && isNotInRedKwList) {
              if (lowercaseKw !== undefined || lowercaseKw.length > 0) {  
                addKeywordInArray(valueToPush, currLocationsSelected, currRefinersSelected);
              }
            } else {
              elemRef.value = "";
              showLoadingSpinner(false);
              setModalMsg("You have already added this keyword.");
              setShowModal(true);
              return;
            }
          }
  
          elemRef.value = "";
        }
      } else if (elemRef !== isValidElement) {
        showLoadingSpinner(false);
        return;
      }
    }
  };

  const removeKeyword = (event) => {
    event.preventDefault();
    let kwArrObj = {}
    let kwToRemove = event.target.value;

    let greenKwsList = [...currFormData.keywordsObj.keywords.value];
    let redKwsList =[...currFormData.keywordsObj.keywordsforreview.value];
    const greenLen = greenKwsList.length;
    const redLen = redKwsList.length;
    
    greenKwsList = greenKwsList.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    redKwsList = redKwsList.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    kwArrObj.greenKws = [...greenKwsList];
    kwArrObj.redKws = [...redKwsList];

    if(parseInt(greenLen) !== parseInt(kwArrObj.greenKws.length)){
      let validState = kwArrObj.greenKws.length > 0;
      if(!validState){
        setCssState("");
        setStatusMessage("");
      }
      updateFormState(fields.greenKwField, kwArrObj.greenKws, validState, true);
    }
    
    if(parseInt(redLen) !== parseInt(kwArrObj.redKws.length)){
      let validState = kwArrObj.redKws.length > 0;
      if(!validState){
        setCssState("");
        setStatusMessage("");
      }
      updateFormState(fields.redKwField, kwArrObj.redKws, validState, true);
    }

    let totalKwList = greenKwsList.concat(redKwsList);
    if(totalKwList.length === 0){
      showSynonymsBox(false); //hide box
      showSynonymsBox(null, false); //hide spinner
    }else if(totalKwList.length > 0){
      removeRelatedSynonyms(kwToRemove);
    }
  };

  const renderKwList = ()=>{

    // use the form data values to render the list of kw 
    // instead of component state,
    // this is to make the use of the parent component.
    // the parent will revalidate the keywords when location or refiner page changes are made.
    // instead of depending on this component state,
    // the parent component will revalidate the keywords and keywordsforreview
    // fields and rearrange it's values into the corresponding arrays,
    // once that is done, the update of the parent object (the main form object)
    // will cause a re-render, and this method will always render the correct keywords

    let greenKwsList = [...currFormData.keywordsObj.keywords.value];
    let redKwsList = [...currFormData.keywordsObj.keywordsforreview.value];

    let allbtnList = [];
    
    for (let i = 0; i < greenKwsList.length; i++) {
      const opt = greenKwsList[i];
      let idStatusClass = " green-kw-class ";
      let btnStatusClass = displayGrayPills ? " btn-gray-class " : " btn-success ";
      
      const nBtn = <div
                    id="keyword-on-list"
                    data-testid="testid-keyword-on-list"
                    aria-label="keyword-on-list"
                    key={opt + "_div"}
                    className={"btn-group " + formPillsStyle}
                    role="group"
                  >
                    <button
                      aria-label={"keyword " + opt}
                      data-testid="kw-pill"
                      key={opt + "_btn_opt_key"}
                      className={
                        "btn " + btnStatusClass + formPillsBtnFontWeight + idStatusClass
                      }
                      type="button"
                      onClick={clipboardHandler}
                    >
                      {opt}
                    </button>
                    {readOnlyMode ? (
                      <></>
                    ) : (
                      <button
                        id={opt + "_remove_btn"}
                        key={opt + "_remove_btn_key"}
                        value={opt}
                        className={"btn " + btnStatusClass + formPillsBtnFontWeight}
                        type="button"
                        aria-label={"remove " + opt + " from list"}
                        onClick={removeKeyword}
                      >
                        x
                      </button>
                    )}
                  </div>
      allbtnList.push(nBtn);
    }


    for (let j = 0; j < redKwsList.length; j++) {
      const opt = redKwsList[j];
      let idStatusClass = " red-kw-class ";
      let btnStatusClass = displayGrayPills ? " btn-gray-class " : " btn-danger ";
      const nBtn =  <div
                      id="keyword-on-list"
                      data-testid="testid-keyword-on-list"
                      aria-label="keyword-on-list"
                      key={opt + "_div"}
                      className={"btn-group " + formPillsStyle}
                      role="group"
                    >
                      <button
                        aria-label={"keyword " + opt}
                        key={opt + "_btn_opt_key"}
                        className={
                          "btn " + btnStatusClass + formPillsBtnFontWeight + idStatusClass
                        }
                        type="button"
                        onClick={clipboardHandler}
                        data-bs-toggle="red-pills-tooltip"
                        data-bs-placement="left"
                        data-bs-title={
                        `Another ${formName} is already associated with this keyword. It will be considered, but may not be implemented.`
                        }
                      >
                        {opt}
                      </button>
                    {readOnlyMode ? (
                      <></>
                    ) : (
                      <button
                        id={opt + "_remove_btn"}
                        key={opt + "_remove_btn_key"}
                        value={opt}
                        className={"btn " + btnStatusClass + formPillsBtnFontWeight}
                        type="button"
                        aria-label={"remove " + opt + " from list"}
                        onClick={removeKeyword}
                      >
                        x
                      </button>
                    )}
                  </div>
      allbtnList.push(nBtn);
    }

    return allbtnList;
  }

  const onClickModalHandler = () => {
    setShowModal(false);
  };

  const plusButton = (
    <div
      onClick={addKeyword}
      onKeyDown={addKeyword}
      className={"plus-icon-css"}
      data-bs-toggle="plus-icon-tooltip"
      data-bs-placement="top"
      data-bs-title={"Add Keyword(s)"}
    >
      <svg
        className={`input-kw-add-btn`}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        data-testid={`testid-${reqType}-kw-plus-icon`}
        id="plus-icon"
        aria-label="Add Users(s)"
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex={0}
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );

  return (
    <div>
      {showModal ? (
        <ModalCustom
          onClick={onClickModalHandler}
          onClose={onClickModalHandler}
          modal_title={"Info"}
          modal_msg={modalMsg}
          secondOption={false}
          btn_1_class={
            isDarkMode
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <div></div>
      )}
      <FieldLabelAndIcon
          reqType={reqType}
          inputType={inputType}
          inputID={`${keywordInputElID}-label`}
          isRequired={true}
          labelText={"Keywords"}
          tooltipText={tooltipText}
      />
      <div className={"kw-input-and-button-div"}>
        <input
          id={keywordInputElID}
          data-testid={`testid-${keywordInputElID}`}
          type="text"
          className={"form-control " + cssState}
          placeholder="Enter at least 1 related keyword (maximum of 20)"
          aria-label="Enter at least 1 related keyword (maximum of 20)"
          onKeyDown={addKeyword}
          disabled={readOnlyMode}
        />
        {readOnlyMode ? <></> : plusButton}
      </div>
      <div className="d-flex keywords-loading-spinner"
        style={{visibility : "hidden"}}
      >
              <div
                className="spinner-border text-light-mode-purple"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
      </div>
      <div>
        <div
          className={"form-control" + cssState + " hidden-delegate-div"}
        ></div>
        <div
          id="kw-error-feedback-div"
          className={"invalid-feedback adjust-error-msg"}
        >
          {statusMessage}
        </div>
      </div>
      <div
        id={`${reqType}-selected-keywords`}
        data-testid={`testid-${reqType}-selected-keywords`}
        className={"selected-keywords-div form-keywords-list-selector"}
        tabIndex={0}
      >
        {renderKwList()}
      </div>
      <KeywordSynonyms
        reqType={reqType}
        formPillsStyle={formPillsStyle}
        formPillsBtnFontWeight={formPillsBtnFontWeight}
        clipboardHandler={clipboardHandler}
        addKeyword={addKeyword}
        synonymsListArr={currSynonymsList}
      />
      <Toast
        toastText="Keyword copied to the clipboard!"
        toastKey="copyToClipboard"
      />
    </div>
  );
};

export default KeywordsField;
