import { useState, useEffect, useContext } from "react";
import TopicCardTooltips from "./TopicCardTooltips";
import rocketImage1 from "../../../../assets/images/rocket_01.svg";
import rocketImage2 from "../../../../assets/images/rocket_02.svg";
import rocketDisabled from "../../../../assets/images/rocket_disabled.svg";
import "../../FormUI/GenAIDescription/genAiModal&Button.css";
import "../TCForm.css";
import TooltipManager from "../../FormUI/TooltipManager";
import GenAiLoadingSpinnerTC from "./GenAiLoadingTC";
import StoreContext from "../../../../store/storecontext";

const TopicURL = ({
  value,
  setValue,
  urlErrorMessage,
  inputState,
  validateURL,
  disableField,
  tooltipFor,
  showGenAiSpinner,
  openGenAiModal,
  disableGenIaBtn,
  hideGenAiFeature,
  showGenAiWarning
}) => {
  let titleURL = {
    tooltip: "Provide a main URL source to display (optional).",
    alt: "Title Info. For better Search results relevancy for this requested Topic Card, enter the site title as it appears when navigating to the entered URL"
  };
  const tooltipBody = `<div class="summarize-description" data-testid="summarize-tooltip">
        <p class="tooltip-header"><b>Summarize</b></p>
        <p class="tooltip-info">
            Click this button to automatically generate the Topic Card description
        </p>
    </div>`;
  useEffect(() => {
    TooltipManager.instantiateTooltips("tc");
  });

  const store = useContext(StoreContext);

  const urlCheckerService = store.services.urlCheckerService;

  const [validInputClass, setValidInputClass] = useState("base");
  const [oldValue, setOldValue] = useState();

  const rocketImage = false ? rocketImage2 : rocketImage1;

  const rocketImg = () => {
    return disableGenIaBtn ? rocketDisabled : rocketImage;
  };

  let regex = urlCheckerService.textRegex;
  const isUrlClean = () => {
    let finalClass = inputState["url"] ? "is-valid" : "is-invalid";
    if (
      !value.match(regex) &&
      value !== "" &&
      finalClass === "is-valid" &&
      !showGenAiSpinner
    ) {
      return showGenAiWarning;
    }
  };

  const genAiWarningMsg =
    "This URL cannot be reached by Gen AI. You may use the form traditionally.";

  useEffect(() => {
    var finalClass;
    if (value === "") {
      finalClass = "";
    } else {
      finalClass = inputState["url"] ? "is-valid" : "is-invalid";
    }
    setValidInputClass(finalClass);
  }, [inputState]);

  const errorMsg = () => {
    if (validInputClass === "is-invalid") {
      return (
        <div id="validate-input" className="invalid">
          {urlErrorMessage}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onUrlChangeHandler = async (event) => {
    let value = event.target.value;
    setValue("url", value);
    await setTimeout(() => {
      validateURL(value);
    }, 450);
    setOldValue(value);
    return !showGenAiWarning;
  };

  const onBlurChangeHandler = () => {
    validateURL(value);
  };

  const genAIRocketBtn = (
    <>
      {showGenAiSpinner && !hideGenAiFeature ? (
        <GenAiLoadingSpinnerTC />
      ) : (
        <button
          className="gen-ai-rocket-button gen-ai-rocket-button-selector"
          onClick={(e) => {
            openGenAiModal(e, disableGenIaBtn);
          }}
          data-testid="gen-ai-rocket-button"
          id="gen-ai-rocket-button"
          aria-label="Generate Best Bet Description"
          aria-describedby="gen-ai-rocket-tooltip"
          disabled={disableGenIaBtn}
        >
          <img
            src={rocketImg()}
            alt="Rocket"
            role="tooltip"
            data-bs-html="true"
            data-bs-toggle="gen-ai-rocket-tooltip"
            data-bs-placement="right"
            data-bs-title={tooltipBody}
            id="rocket-image"
          />
        </button>
      )}
    </>
  );

  return (
    <>
      <div className="mb-3 url">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={titleURL.alt}
          tooltipText={titleURL.tooltip}
        />
        <label data-testid="topic-card-label" className="label-text">
          Topic URL
        </label>
        <div className="tcurl-and-rocket-styles">
          <input
            id="tc-form-url-input"
            type="text"
            data-testid="tc-input"
            className={`form-control ${validInputClass} tc-url-tag`}
            value={value}
            placeholder={"Enter a URL for this Topic Card (optional)"}
            onChange={onUrlChangeHandler}
            onBlur={onBlurChangeHandler}
            disabled={disableField}
          />
          <>{genAIRocketBtn}</>
        </div>

        {errorMsg()}
        {!hideGenAiFeature && showGenAiWarning && isUrlClean() && (
          <div
            id="genai-warning-msg-box"
            style={{
              visibility: "visible",
              color: "var(--light-mode-genaiwarning-text-color)"
            }}
            className="tc-genai-msg"
          >
            {genAiWarningMsg}
          </div>
        )}
      </div>
    </>
  );
};

export default TopicURL;
