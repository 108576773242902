

import tooltipIcon from "../../../../assets/images/info.svg";
const FieldLabelAndIcon = ({reqType, inputType, inputID, isRequired, labelText, tooltipText})=>{

    return (
        
        <div>
            <img
                id={`${reqType}-tooltip-icon-${labelText}`}
                src={tooltipIcon}
                role="tooltip"
                alt={tooltipText}
                data-bs-html="true"
                data-bs-toggle={`${reqType}-tooltip`}
                data-bs-placement="left"
                data-bs-title={tooltipText}
                tabIndex="0"
            />
            <label 
             className={`label-text ${isRequired ? "required" : ""}`}
             htmlFor={inputID}
             data-testid={`${reqType}-${inputType}-field-label`}
            >
             {labelText}
            </label>
        </div>
    
    )
}

export default FieldLabelAndIcon;