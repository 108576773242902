import { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();
const ThemeProvider = (props) => {
  const [theme, setTheme] = useState("");

  const getThemeInStorage = () => {
    const stored = localStorage.getItem("theme");
    if (!stored) {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
    return localStorage.getItem("theme");
  };

  useEffect(() => {
    let currTheme = getThemeInStorage();
    setTheme(currTheme);
  }, []);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      {props.children}
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
