


// this is a wrapper to handle the logic for saving and editing the request with the buttons,
// the FormButtons component is ui and hooks for the methods that will be executed here,
// all the forms will have their unique wrappers, but the same FormButtons component import

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CommentTracker from "../FormUI/CommentHistory/CommentTracker.js"
import FormButtons from "../FormUI/FormButtons";

const BBButtonsWrapper = ({reqType,
    formName,
    idFromUrl,
    disableFormButtons,
    disableDraftButton,
    cssTheme,
    appStore,
    currFormData,
    dataFromIndex,
    showPreview,
    callCompareTable,
    showLoadingSpinner,
    setModalProps})=>{

    const btnServices = appStore.services;
    const [isAdmin,setIsAdmin] = useState(appStore.state.isAdmin);
    const [intID, setIntID] = useState(null);
    const [btnBools, setBtnBools] = useState({
      bShowCompareButton : null,
      bShowPreviewButton : null,
      bShowSaveChangesButton : null,
      bShowCancelRequestButton : null,
      bShowRemoveButton : null,
      bShowDraftButton : null,
      bShowRequestUpdateBtn : null,
      bShowSubmitForRemovalBtn : null,
      bShowRejectButton : null
    });


    const navigate = useNavigate();

    const showPreviewHandler = (event)=>{
        event.preventDefault();
        showPreview();
    }

    const getPostMessage = (comment, type, reqId, title, ownerYammerId) => {
        const commentMsg = comment.comment;
        const adminCreation = commentMsg.includes("and implemented") ? "new " : "";
        const implementedAdmin = commentMsg.includes("and implemented")
          ? " and implemented"
          : ",";
        const requesttype = type === "Creation" ? "" : `${type.toLowerCase()} `;
        const initialMsg = `A ${adminCreation}Best Bet ${requesttype}request was submitted${implementedAdmin}`;
    
        const msgBody = ` (${reqId} -  ${title.replace('"', "&quot;")}). ${
          process.env.REACT_APP_SEARCHTOOLS_URL
        }/promote/bestbet/request/${reqId}. `;
        return initialMsg + msgBody + ownerYammerId;
    };

    const getSaveChangesComments = (commentsArr) => {
        const filtered = commentsArr.filter(
          (comment) =>
            comment.user === "System" &&
            comment.comment.startsWith("This request was modified by")
        );
        return filtered.map((comment) => comment.comment);
    };

    const getYammerOwnerId = (owner) => {
        return btnServices.yammerService.GetOwnerUserIdForYammer(owner);
    };

    const formHasUpdates = () => {
        return (
          currFormData.title.value !== dataFromIndex.cleantitle ||
          currFormData.url.value !== dataFromIndex.cleanurl ||
          currFormData.description.value !== dataFromIndex.cleandescription ||
          JSON.stringify(currFormData.refinerPages.value) !== JSON.stringify(dataFromIndex.aid) ||
          JSON.stringify(currFormData.countries.value) !==
            JSON.stringify(dataFromIndex.cleancountry) ||
          JSON.stringify(currFormData.keywordsObj.keywords.value) !==
            JSON.stringify(dataFromIndex.cleankeywordsraw) ||
          JSON.stringify(currFormData.keywordsObj.keywordsforreview.value) !==
            JSON.stringify(dataFromIndex.cleankeywordsforreviewraw)
        );
    };

    const postAndCommentOnYammer = async (
        bestbetrequest,
        type,
        ownerYammerId,
        reqid,
        lastcommentMessage
      ) => {
        
        const originalPostMsg = getPostMessage(
          bestbetrequest.comments.pop(),
          type,
          bestbetrequest.bbrequestid,
          bestbetrequest.cleantitle,
          ownerYammerId
        ); //eg: a best bet removal request was submitted...
        
        const changesComments = getSaveChangesComments(
          bestbetrequest.comments //This request was modified...
            .slice(1)
        ); //the first comment on the array is the last action
        
        const response = await btnServices.yammerService.PostBestBetToYammer(
          bestbetrequest,
          appStore.state.isAdmin,
          reqid,
          ownerYammerId,
          originalPostMsg
        );
        
        const yammerthreadid = response.data;
        var promises = [];
        changesComments.forEach((comment) =>
          promises.push(
            btnServices.yammerService.PostYammerComment(yammerthreadid, [
              `This request was modified. ${ownerYammerId}`
            ])
          )
        );
        await Promise.all(promises);
        await btnServices.yammerService.PostYammerComment(yammerthreadid, [
          lastcommentMessage
        ]);
    };

    const addArcDataToBbBody = (_arcData, _bbBody) => {
        _bbBody.cleanapproved = "";
        _bbBody.cleanofficial = "";
        _bbBody.contentsource = "";
        _bbBody.approvedby = "";
        _bbBody["1aaa"] = "";
    
        if (_arcData.hasOwnProperty("cleanapproved")) {
          _bbBody.cleanapproved = _arcData.cleanapproved;
        }
        if (_arcData.hasOwnProperty("cleanofficial")) {
          _bbBody.cleanofficial = _arcData.cleanofficial;
        }
    
        if (_arcData.hasOwnProperty("cleancontentsource")) {
          _bbBody.contentsource = _arcData.cleancontentsource;
        }
    
        if (_arcData.hasOwnProperty("approvedby")) {
          _bbBody.approvedby = _arcData.approvedby;
        }
    
        if (_arcData.hasOwnProperty("1aaa")) {
          _bbBody["1aaa"] = _arcData["1aaa"];
        }
    
        return _bbBody;
    };

    const getArcDataForBestBet = async (_url, _requestId) => {
        const r = await btnServices.bestBetService.getBbArcDataFromUrl(
          _url,
          _requestId
        );
    
        let bbHits = r.data.hits?.hits;
    
        let matchIndex = null;
        for (let i = 0; i < bbHits.length; i++) {
          const res = bbHits[i];
          if (res._source.cleanurl?.includes(_url)) {
            matchIndex = i;
          }
          if (res._source.friendlyurl?.includes(_url)) {
            matchIndex = i;
          }
          if (res._source.posthomeurl?.includes(_url)) {
            matchIndex = i;
          }
        }
        let arcProps = {};
        if (matchIndex !== null) {
          let matchData = bbHits[matchIndex]._source;
    
          if (matchData.hasOwnProperty("cleanapproved")) {
            arcProps.cleanapproved = matchData.cleanapproved;
          }
          if (matchData.hasOwnProperty("cleanofficial")) {
            arcProps.cleanofficial = matchData.cleanofficial;
          }
          if (matchData.hasOwnProperty("cleancontentsource")) {
            arcProps.cleancontentsource = matchData.cleancontentsource;
          }
          if (matchData.hasOwnProperty("approvedby")) {
            arcProps.approvedby = matchData.approvedby;
          }
          if (matchData.hasOwnProperty("1aaa")) {
            arcProps["1aaa"] = matchData["1aaa"];
          }
        }
        return arcProps;
    };


    const PostCommentsInTheTracker = (request, commentBoxValue) => {
        let user = localStorage.getItem("searchtools.eid");
        let comments = [];
        request.user = user;
        comments = CommentTracker.PostComments(request);
    
        if (commentBoxValue !== "") {
          comments.unshift(CommentTracker.SaveComment(commentBoxValue, user));
        }
        return comments;
    };


    const buildRequestJson = (status, type, form) => {
        
        let bestbetrequest = {};
        const owner = form.requestedby || localStorage.getItem("searchtools.eid");
    
        bestbetrequest.bbrequestid = form.requestId;
    
        if (form.bbid) {
          bestbetrequest.bbid = form.bbid;
        }
    
        bestbetrequest.cleanemployeenumber = idFromUrl
          ? form.cleanemployeenumber
          : localStorage.getItem("searchtools.pk");
    
        let dt = new Date().toISOString();
    
        bestbetrequest.cleancreationdate = form.cleancreationdate || dt;
        bestbetrequest.lastmodifieddateUTC = dt;
        if (form.lastmodifiedby.length === 0) {
          bestbetrequest.lastmodifiedby = [];
        } else if (form.lastmodifiedby.length > 0) {
          bestbetrequest.lastmodifiedby = form.lastmodifiedby;
        }
        bestbetrequest.lastmodifiedby.push({
          user: localStorage.getItem("searchtools.eid") || "localhost",
          dateUTC: dt
        });
        bestbetrequest.requestedby = owner || "localhost";
        bestbetrequest.cleanexpirationdate = null;
        bestbetrequest.cleansimilarurlimplemented = null; //for validations, for sites with subsites
        bestbetrequest.cleaninternalsitesstatus = null; //for validations
        bestbetrequest.cleancontributionapproved = null; //for validations
        bestbetrequest.cleanmylearningonlinetraining = null; //for validations
        bestbetrequest.cleanstatus = status;
        bestbetrequest.cleanrequesttype = type;
        bestbetrequest.cleankeywords = form.keywordsObj.keywords.value.map((kw) => {
          return kw.toLowerCase();
        });
        bestbetrequest.cleankeywordsraw = form.keywordsObj.keywords.value;
        bestbetrequest.cleankeywordsforreview = form.keywordsObj.keywordsforreview.value.map((kw) => {
          return kw.toLowerCase();
        });
        bestbetrequest.cleankeywordsforreviewraw = form.keywordsObj.keywordsforreview.value;
        bestbetrequest.cleantitle = form.title.value;
        bestbetrequest.cleandescription = form.description.value;
        bestbetrequest.cleanurl = decodeURIComponent(form.url.value);
        bestbetrequest.cleancountry = form.countries.value;
        bestbetrequest.aid = form.refinerPages.value;
        bestbetrequest.useragent = navigator.userAgent;
        bestbetrequest.yammerthreadid = form.yammerthreadid;
        if (form.comments === undefined || form.comments.length === 0) {
          bestbetrequest.comments = [];
        } else if (form.comments.length > 0) {
          bestbetrequest.comments = form.comments;
        }
    
        return bestbetrequest;
    };

    const submitRequest = async (status, type, event) => {
        let action = event.target.innerHTML;
        event.preventDefault();

        let form = {...currFormData};
        const owner = form.requestedby || localStorage.getItem("searchtools.eid");

        showLoadingSpinner(true, true);

        let requestBody = [];
        let bestbetrequest = buildRequestJson(status, type, form);
        let bestbet = {};
        let request = {
          action: action,
          status: bestbetrequest.cleanstatus,
          reqType: bestbetrequest.cleanrequesttype,
          requestId: idFromUrl
        };

        if (!idFromUrl) {
          bestbetrequest.comments = PostCommentsInTheTracker(request, form.commentBox.value);
        } else {
          if (action === "Save Changes") {
            if (formHasUpdates()) {
              bestbetrequest.comments = PostCommentsInTheTracker(request, form.commentBox.value).concat(
                bestbetrequest.comments
              );
            } else {
              if (form.commentBox.value !== "") {
                let user = localStorage.getItem("searchtools.eid");
                bestbetrequest.comments.unshift(
                  CommentTracker.SaveComment(form.commentBox.value, user)
                );
              }
            }
          } else {
            bestbetrequest.comments = PostCommentsInTheTracker(request, form.commentBox.value).concat(
              bestbetrequest.comments
            );
          }
        }
    
        requestBody.push(bestbet);
        requestBody.push(bestbetrequest);
    
        let bbrequestid = form.requestId === null ? 0 : parseInt(form.requestId);
    
        if (bbrequestid <= 0) {
          //creation
          try {
            const r = await btnServices.bestBetService.SaveBestBet(
              requestBody,
              0
            );
            bestbetrequest.cleanrequesttype = form.cleanrequesttype;
            const ownerYammerId = await getYammerOwnerId(owner);
            await btnServices.yammerService.PostBestBetToYammer(
              bestbetrequest,
              isAdmin,
              r.data._id,
              ownerYammerId
            );

            showLoadingSpinner(false, true);

            if (action === "Request Best Bet" && !appStore.state.isSuperUser) {
              appStore.setShowFeedbackBox(1);
            }
            setModalProps(
              "Best Bet request " +
                r.data._id +
                ' was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.',
              () => navigate("/myrequests")
            );
          } catch {
            
            showLoadingSpinner(false, true);
            setModalProps(
              "Error has been encountered. Try again later.",
              () => {} //don't do anything when closing modal
            );
          }
        } else {
          //update
          if (!isAdmin) {
            bestbetrequest.requestedby = localStorage.getItem("searchtools.eid");
          }
    
          try {
            let r = null;
            const isActive =
              form.cleanstatus === "Implemented" || form.cleanstatus === "Active";
            if (
              isActive &&
              (form.cleanrequesttype === "Update" ||
                form.cleanrequesttype === "Removal" ||
                form.cleanrequesttype === "Creation")
            ) {
              r = await btnServices.bestBetService.SaveBestBet(requestBody, 0);
            } else if (type === "Update" && status === "New") {
              r = await btnServices.bestBetService.SaveBestBet(
                requestBody,
                form.requestId
              );
            } else if (type === "Removal") {
              r = await btnServices.bestBetService.SaveBestBet(
                requestBody,
                form.requestId
              );
            } else if (status === "Not Approved") {
              let statusMessage =
                status === "Cancelled" || status === "Removed"
                  ? status.lowercase()
                  : "updated.";
              let isRequest =
                status !== "Removed" ? `request ${form.requestId}` : "";
    
              let msg = `Best bet ${isRequest} has been ${statusMessage}`;
    
              r = await btnServices.bestBetService.SaveBestBet(
                requestBody,
                form.requestId
              );
    
              showLoadingSpinner(false, true);
              setModalProps(msg, () => navigate("/myrequests")); //go to /myrequest when closing modal
            } else {
              r = await btnServices.bestBetService.Update(
                requestBody,
                form.requestId,
                ""
              );
            }
            let lastcommentMessage;
            const ownerYammerId = await getYammerOwnerId(
              bestbetrequest.requestedby
            );
            if (status === "Not Approved") {
              lastcommentMessage = `Your request was not approved. Please review it for more details`;
            } else {
              lastcommentMessage = `This request was `;
              if (status === "New") {
                lastcommentMessage += "modified";
              } else lastcommentMessage += status.toLowerCase();
            }
    
            lastcommentMessage += `. ${ownerYammerId}`;
    
            if (type === "Update" || type === "Removal") {
              let modalMsg = `Best Bet request ID # ${r.data._id} has been updated`;
              showLoadingSpinner(false, true);
              setModalProps(modalMsg, () => navigate("/myrequests")); //go to /myrequest when closing modal
            } else {
              showLoadingSpinner(false, true);
              setModalProps(
                `Best Bet request ${r.data._id} has been updated.`,
                () => navigate("/myrequests")
              ); //go to /myrequest when closing modal
            }

            const doComment = action !== "Save Changes" || formHasUpdates();
            let isNewRequestFromImplement =
              isActive &&
              (bestbetrequest.cleanrequesttype === "Update" ||
                bestbetrequest.cleanrequesttype === "Removal" ||
                bestbetrequest.cleanrequesttype === "Creation");
            if (isNewRequestFromImplement) {
              btnServices.yammerService.PostBestBetToYammer(
                bestbetrequest,
                isAdmin,
                r.data._id,
                ownerYammerId
              );
            } else if (form.yammerthreadid && doComment) {
              //If there is a yammer post and this is not a request update/submit for removal we want to comment the post
              btnServices.yammerService.PostYammerComment(form.yammerthreadid, [
                lastcommentMessage
              ]);
            } else if (doComment) {
              //If there is no post, we have to create it and post all the system comments stating "this reques was modified"
              await postAndCommentOnYammer(
                bestbetrequest,
                type,
                ownerYammerId,
                r.data._id,
                lastcommentMessage
              );
            }
          } catch (error) {
            showLoadingSpinner(false, true);
            appStore.setShowFeedbackBox(0);
            setModalProps("Error was encountered. Try again later.", () => {});
          }
        }
    };

    const implementBestbet = async (rqstStatus, rqstType, event) => {
        
        event.preventDefault();

        let action = event.target.innerHTML;
        
        showLoadingSpinner(true, true);

        let form = {...currFormData};

        const isAdmin = appStore.state.isAdmin;
        const owner = form.requestedby || localStorage.getItem("searchtools.eid");

        let requestBody = [];
        if (rqstStatus === "New" && rqstType === "Creation") {
          rqstStatus = isAdmin ? "Implemented" : "New";
        } else if (rqstStatus === "New" && rqstType === "Removal") {
          rqstStatus = "Implemented";
          form.requestId = 0;
        }else if (rqstStatus === "New" && rqstType === "Update" && isAdmin) {
          rqstStatus = "Implemented";
          rqstType = "Update"
        }else if(rqstStatus === "Implemented"){
          rqstType = "Update"
        }
    
        let bestbetrequest = buildRequestJson(rqstStatus, rqstType, form);
    
        let request = {
          action: action,
          status: bestbetrequest.cleanstatus,
          reqType: bestbetrequest.cleanrequesttype,
          requestId: idFromUrl
        };
    
        if (!idFromUrl) {
          bestbetrequest.comments = PostCommentsInTheTracker(request, form.commentBox.value);
        } else {
          bestbetrequest.comments = PostCommentsInTheTracker(request, form.commentBox.value).concat(
            bestbetrequest.comments
          );
        }

        if (!form.bbid) {
          //creation
          let bestbet = {};
          bestbet.cleanstatus = "Active";
          bestbet.cleantitle = form.title.value;
          bestbet.cleandescription = form.description.value;
          bestbet.cleanurl = decodeURIComponent(form.url.value);
          bestbet.cleancountry = form.countries.value;
          bestbet.showtocountry = form.countries.value;
          bestbet.aid = form.refinerPages.value;
          bestbet.cleankeywords = form.keywordsObj.keywords.value.map((kw) => {
            return kw.toLowerCase();
          });
          bestbet.cleangr1 = form.keywordsObj.keywords.value;
          bestbet.cleankeywordsraw = form.keywordsObj.keywords.value;
    
          bestbet.cleankeywordsforreview = form.keywordsObj.keywordsforreview.value.map((kw) => {
            return kw.toLowerCase();
          });
          bestbet.cleankeywordsforreviewraw = form.keywordsObj.keywordsforreview.value;
    
          bestbet.createdby = owner || "localhost";
          bestbet.qualityscore = parseInt(form.qualityscore);
    
          let bestBetArcProps = null;
          try {
            bestBetArcProps = await getArcDataForBestBet(form.url.value, form.requestId);
          } catch (error) {
            console.log("error on getting ARC for Best Bet");
          }
    
          if (bestBetArcProps !== null) {
            let bbTempBody = { ...bestbet };
            bestbet = addArcDataToBbBody(bestBetArcProps, bbTempBody);
          }
    
          requestBody.push(bestbet);
          requestBody.push(bestbetrequest);
          let lastCommentMessage = "";
    
          const ownerYammerId = await getYammerOwnerId(owner);
          if (rqstStatus === "Implemented") {
            lastCommentMessage =
              "Your request has been implemented. Please review it for more details. " +
              ownerYammerId;
          } else if (rqstStatus === "Waiting for customer") {
            lastCommentMessage =
              "Your request cannot be analyzed and requires more information from your end. Please review and edit it accordingly. " +
              ownerYammerId;
          }
          try {
            const r = await btnServices.bestBetService.SaveBestBet(
              requestBody,
              form.requestId
            );
            if (form.yammerthreadid) {
              await btnServices.yammerService
                .PostYammerComment(form.yammerthreadid, [lastCommentMessage])
                .catch((e) => {
                  console.log(
                    "error in implementBestbet 1 -> PostYammerComment : " + e
                  );
                });
            } else if (!bestbetrequest.bbrequestid) {
              //If it's admin autoimplementation
              await btnServices.yammerService.PostBestBetToYammer(
                bestbetrequest,
                isAdmin,
                r.data._id,
                ownerYammerId
              );
            } else {
              await postAndCommentOnYammer(
                bestbetrequest,
                rqstType,
                ownerYammerId,
                r.data._id,
                lastCommentMessage
              );
            }

            showLoadingSpinner(false, true);

            setModalProps(
              "Best Bet request " +
                r.data._id +
                " has been saved and implemented. You may verify its implementation on Search.",
              () => navigate("/myrequests")
            ); //go to /myrequest when closing modal

          } catch {

            showLoadingSpinner(false, true);

            setModalProps(
              "Error has been encountered. Try again later.",
              () => {} //don't do anything when closing modal
            );
          }
        } else {
          //  update
          var bestbetjson = {};
    
          bestbetjson.cleanstatus = rqstType === "Removal" ? "Inactive" : "Active";
          bestbetjson.cleantitle = form.title.value;
          bestbetjson.cleandescription = form.description.value;
          bestbetjson.cleanurl = decodeURIComponent(form.url.value);
          bestbetjson.cleancountry = form.countries.value;
          bestbetjson.showtocountry = form.countries.value;
          bestbetjson.aid = form.refinerPages.value;
          bestbetjson.cleankeywords = form.keywordsObj.keywords.value.map((kw) => {
            return kw.toLowerCase();
          });
          bestbetjson.cleangr1 = form.keywordsObj.keywords.value;
          bestbetjson.cleankeywordsraw = form.keywordsObj.keywords.value;
          bestbetjson.cleankeywordsforreview = form.keywordsObj.keywordsforreview.value.map((kw) => {
            return kw.toLowerCase();
          });
          bestbetjson.cleankeywordsforreviewraw = form.keywordsObj.keywordsforreview.value;
    
          bestbetjson.qualityscore = parseInt(form.qualityscore);
    
          let bestBetArcProps = null;
          try {
            bestBetArcProps = await getArcDataForBestBet(form.url.value, form.requestId);
          } catch (error) {
            console.log("error on getting ARC for Best Bet");
          }
    
          if (bestBetArcProps !== null) {
            let bbTempBody = { ...bestbetjson };
            bestbetjson = addArcDataToBbBody(bestBetArcProps, bbTempBody);
          }
    
          requestBody.push(bestbetjson);
          requestBody.push(bestbetrequest);
    
          let requestID = form.cleanstatus === "New" ? form.requestId : 0;
    
          let r = await btnServices.bestBetService.Update(
            requestBody,
            requestID,
            form.bbid
          );

          let lastCommentMessage;
          const ownerYammerId = await getYammerOwnerId(owner);
          if (
            (rqstType === "Update" || rqstType === "Removal") &&
            form.cleanstatus === "New"
          ) {
                lastCommentMessage =
                "Your " +
                bestbetrequest.cleanrequesttype.toLowerCase() +
                " request has been implemented. Please review it for more details. " +
                ownerYammerId;
                if (form.yammerthreadid) {
                await btnServices.yammerService
                    .PostYammerComment(form.yammerthreadid, [lastCommentMessage])
                    .catch((e) => {
                    console.log(
                        "error in implementBestbet 2 -> PostYammerComment : " + e
                    );
                    });
                } else {
                    await postAndCommentOnYammer(
                        bestbetrequest,
                        rqstType,
                        ownerYammerId,
                        bestbetrequest.bbrequestid,
                        lastCommentMessage
                    );
                }
            } else if (
                form.cleanrequesttype === "Removal" ||
                form.cleanrequesttype === "Update" ||
                form.cleanstatus === "Implemented" //Autoimplement removal or update
            ) {
                const ownerYammerId = await getYammerOwnerId(owner);
                btnServices.yammerService.PostBestBetToYammer(
                bestbetrequest,
                isAdmin,
                r.data._id,
                ownerYammerId
                );
            }
            let msg;
        
            msg =
                rqstType === "Removal"
                ? "Best Bet has been removed."
                : `Best Bet request ${r.data._id} has been updated and implemented. You may verify its implementation on Search.`;
        
            showLoadingSpinner(false, true);
            setModalProps(msg, () => navigate("/myrequests"));
        }
    };

    const showCompareTable = (e) => {
      e.preventDefault();
      callCompareTable();
    };

    const bntActionsHandler = (e, _actionToExecute) =>{
        switch (_actionToExecute) {

            case "Implement Request Btn":
                implementBestbet(currFormData.cleanstatus, currFormData.cleanrequesttype, e);    
            break;

            case "Remove Request Btn":
                implementBestbet("New", "Removal", e);   
            break;

            case "Request Update Btn":
                submitRequest("New", "Update", e);
            break;

            case "Submit For Removal Btn":
                submitRequest("New", "Removal", e);
            break;

            case "Submit New Request Btn":
                submitRequest("New", currFormData.cleanrequesttype, e);
            break;

            case "Save Changes Btn":
                submitRequest(currFormData.cleanstatus, currFormData.cleanrequesttype, e);
            break;

            case "Cancel Request Btn":
                submitRequest("Cancelled", currFormData.cleanrequesttype, e);
            break;

            case "Reject Btn":
                submitRequest("Not Approved", currFormData.cleanrequesttype, e);
            break;

            case "Submit Draft Btn":
                let reqStatusA = currFormData.cleanrequesttype === "Creation" ? "Creation" : "Update";
                submitRequest("Draft", reqStatusA, e);
            break;

            case "Show Preview Btn":
                showPreviewHandler(e);
            break;

            case "Compare Table Btn":
                showCompareTable(e);
            break;

            default:
                break;
        }
    }

    useEffect(() => {

        // updates the isAdmin variable
        try {
            if(intID === null){
                const nIntervId = setInterval(()=>{
                    try {
                        let btnElm2 = document.querySelector("[id*='-implement-button']");
                        if(btnElm2.classList.length > 0){
                            clearInterval(nIntervId);
                            clearInterval(intID);
                        }
                    } catch (error) {}

                    setIsAdmin(appStore.state.isAdmin);
                }, 700);
                setIntID(nIntervId);
            }
        } catch (error) {}

    }, []);

    // sets which buttons are displayed when the form is rendered
    // check if empty or intID works
    useEffect(()=>{

      let showCompareButton = null;
      let showPreviewButton = null;
      let showSaveChangesButton = null;
      let showCancelRequestButton = null;
      let showRemoveButton = null;
      let showDraftButton = null;
      let showRequestUpdateBtn = null;
      let showSubmitForRemovalBtn = null;
      let showRejectButton = null;
      let showRequestNewFormBtn = null;
      let itsFromManageMyRequest =
        appStore.state?.requestToEditBB?.itsFromManageMyRequest;
      let hasRequestID = parseInt(currFormData.requestId) > 0;
      let hasImplementedID = parseInt(currFormData?.bbid) > 0;
      let isCreationMode =
        currFormData.cleanrequesttype === "Creation" && currFormData.cleanstatus === "New";
      let isUpdateMode = currFormData.cleanrequesttype === "Update";
      let isRemoveRequest = currFormData.cleanrequesttype === "Removal";
      let isStatusNew = currFormData.cleanstatus === "New";
      let isCreationAndImplement = currFormData.cleanrequesttype === "Creation" && currFormData.cleanstatus === "Implemented";
      let isDraftMode = currFormData.cleanstatus === "Draft";
      let isNewCreation = currFormData.cleanrequesttype === "Creation" && currFormData.cleanstatus === "New";
      let isNewUpdate = currFormData.cleanrequesttype === "Update" && currFormData.cleanstatus === "New";
      let isNewRemove = currFormData.cleanrequesttype === "Removal" && currFormData.cleanstatus === "New";

      if (isAdmin) {

        if(currFormData.cleanstatus === "Implemented"){
          showRemoveButton = true;
          showPreviewButton = true;
        }
        else if(isNewCreation || isNewRemove){
          showRejectButton =  hasRequestID;
          showPreviewButton = true;
          showSaveChangesButton = hasRequestID;
        }else if(isNewUpdate){
          showRejectButton =  true;
          showCompareButton = true;
          showPreviewButton = true;
          showSaveChangesButton = hasRequestID;
        }
      }else if(!isAdmin){

        if(isCreationMode){

          if(!hasRequestID){
            showPreviewButton = true;
            showDraftButton = true;
            showRequestUpdateBtn = false;
            showRequestNewFormBtn = true;
          }else if(hasRequestID){
            showCancelRequestButton = true;
            showPreviewButton = true;
            showDraftButton = true;
            showSaveChangesButton = true;
            showRequestNewFormBtn = false;
          }

        }else if(isUpdateMode){
          showSubmitForRemovalBtn = currFormData.cleanstatus === "Implemented";
          showPreviewButton = true;
          showDraftButton = true;
          showCancelRequestButton = currFormData.cleanstatus !== "Implemented";
          showSaveChangesButton = currFormData.cleanstatus !== "Implemented";
          showCompareButton = currFormData.cleanstatus !== "Implemented";
          showRequestUpdateBtn = currFormData.cleanstatus === "Implemented";
        }else if(isCreationAndImplement){
          showSubmitForRemovalBtn = true;
          showPreviewButton = true;
          showDraftButton = true;
          showRequestUpdateBtn = true;
        }else if(isDraftMode){
          showPreviewButton = true;
          showRequestNewFormBtn = true;
        }else if(isRemoveRequest && isStatusNew){
          showCancelRequestButton = true;
          showCompareButton = true;
          showPreviewButton = true;
          showDraftButton = true;
          showSaveChangesButton = true;
        }
      }

      let currBtnBools = {
        bShowPreviewButton : showPreviewButton,
        bShowDraftButton : showDraftButton,
        bShowRequestUpdateBtn : showRequestUpdateBtn,
        bShowRequestNewFormBtn : showRequestNewFormBtn,
        bShowCancelRequestButton : showCancelRequestButton,
        bShowSaveChangesButton : showSaveChangesButton,
        bShowRejectButton : showRejectButton,
        bShowRemoveButton : showRemoveButton,
        bShowCompareButton : showCompareButton,
        bShowSubmitForRemovalBtn : showSubmitForRemovalBtn
      };

      setBtnBools({...currBtnBools});

    },[currFormData]);    

    return (
        <FormButtons
            reqType={reqType}
            formName={formName}
            disableFormButtons={disableFormButtons}
            disableDraftButton={disableDraftButton}
            cssTheme={cssTheme}
            isAdmin={isAdmin}
            btnBools={btnBools}
            bntActionsHandler={bntActionsHandler}
        />
    );

}

export default BBButtonsWrapper;