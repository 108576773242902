import "../HowToForm.css";
import { ThemeContext } from "../../../../ThemeContext";
import { useContext } from "react";
import StoreContext from "../../../../store/storecontext";
import { useNavigate } from "react-router-dom";
import ModalCustom from "../../../Modal/ModalCustom";
import { useState } from "react";
import CompareTable from "./CompareTable";
import * as CommentTracker from "../../FormUI/CommentHistory/CommentTracker.js"

const HtButtons = ({
  isAdmin,
  disableButton,
  disableDraftButton,
  isFormReadOnly,
  form,
  setIsLoading,
  originalData,
  idFromUrl,
  openPreview,
  setPreviewContent,
  modal,
  yammerService
}) => {
  const [theme] = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const [displayCompareTable, setDisplayCompareTable] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    displayModal: false,
    action: "",
    status: ""
  });
  const navigate = useNavigate();

  const buildRequestJson = (status = "", action = "") => {
    let JsonBody = [];
    let currentDateTime = new Date();
    let currentUTCDate = currentDateTime.toISOString();

    let countriesSet = new Set(form.countries);
    let updatedCountriesArr = [...countriesSet];

    form.countries = updatedCountriesArr;
    let formStatus = status;

    let requestedbyUser = "";

    try {
      requestedbyUser = originalData.htReqData.requestedby;
    } catch (error) {
      requestedbyUser = localStorage.getItem("searchtools.eid") || "localhost";
    }

    let requestBody = {
      cleantitle: form.title.trim(),
      cleanurl: form.url.trim(),
      cleansteps: form.step.map((s) => s.step.trim()),
      aid: form.aid,
      cleancountry: form.countries,
      cleankeywords: form.keyword.map((kw) => {
        return kw.toLowerCase();
      }),
      cleankeywordsraw: form.keyword,
      cleaninvalidkeywords: form.keywordsForReview.map((kw) => {
        return kw.toLowerCase();
      }),
      cleaninvalidkeywordsraw: form.keywordsForReview,
      cleanstatus: formStatus,
      comments: form.arrComments,
      requestedby: requestedbyUser,
      cleancreationdate: form.creationdate || currentUTCDate,
      lastmodifieddateUTC: currentUTCDate,
      lastmodifiedby: form.lastmodifiedby,
      yammerthreadid: form.yammerthreadid,
      yammerownerid: form.yammerownerid,
      howto_req_id: form.howto_req_id,
      howto_id: form.howto_id,
      contacts: form.contacts,
      delegates: form.delegates,
      lastmodifiedby: form.lastmodifiedby
    };
    requestBody.lastmodifiedby.push({
      user: localStorage.getItem("searchtools.eid") || "localhost",
      dateUTC: currentUTCDate
    });
    let requestInfo = {
      action: action,
      status: status,
      requestId: idFromUrl,
      reqType: null
    };
    const newComments = PostCommentsInTheTracker(requestInfo);
    requestBody.comments = newComments.concat(requestBody.comments);

    if (idFromUrl) {
      if (!formHasUpdates() && action === "Save Changes") {
        requestBody.cleanstatus = form.status;
      }
    }
    let howtobody = {
      howto_req_id: requestBody.howto_req_id,
      cleantitle: requestBody.cleantitle,
      cleanurl: requestBody.cleanurl,
      cleansteps: requestBody.cleansteps,
      cleancountry: requestBody.cleancountry,
      aid: requestBody.aid,
      cleankeywords: requestBody.cleankeywords,
      cleankeywordsraw: requestBody.cleankeywordsraw,
      cleanstatus: status === "Implemented" ? "Active" : "Inactive",
      cleancreationdate: form.creationdate || currentUTCDate,
      delegates: form.delegates
    };
    if (status === "Implemented" || status === "Inactive") {
      JsonBody.push(howtobody);
    } else {
      JsonBody.push({});
    }
    JsonBody.push(requestBody);
    return JsonBody;
  };
  const getPostingMessage = (action, reqId = 0, title, id) => {
    var msg;
    const requestUrl = `${process.env.REACT_APP_SEARCHTOOLS_URL}/promote/how-to/request/${id}`;
    const creationBaseMsg = `A new How-To Card request was submitted`;
    const implementMsg =
      reqId === 0
        ? `${creationBaseMsg} and implemented, ${requestUrl} (${id} - ${title})`
        : "Your request has been implemented";
    switch (action) {
      case "Request How-To":
        msg = `${creationBaseMsg}. ${requestUrl} (${id} - ${title})`;
        break;
      case "Implement":
        msg = `${implementMsg}. `;
        break;
      case "Save Changes":
        msg = `This request was modified. `;
        break;
      case "Remove":
        msg = `Your request was removed. `;
        break;
      case "Cancel":
        msg = `This request was cancelled. `;
        break;
      case "Reject":
        msg = "Your request was not approved. Please review for more details.";
        break;
      default:
    }
    return msg;
  };
  const postOnYammer = async (rqstBody, action, id) => {
    let title;
    let requestId;
    let requestIndex;
    let usersToTag;
    let delegatesToPost =
      form.delegates.length > 0
        ? `[[user:${form.delegates
            .map((delegate) => delegate.userid)
            .join("]]  [[user:")}]]`
        : "";
    title = rqstBody.cleantitle;
    requestId = rqstBody.howto_req_id;
    requestIndex = process.env.REACT_APP_HTREQUEST_IDX;
    const tagOwnerOnYammer = await yammerService.GetOwnerUserIdForYammer(
      rqstBody.requestedby
    );
    usersToTag = tagOwnerOnYammer + " " + delegatesToPost;

    let streamBody = [];

    let postingMessage =
      getPostingMessage(action, requestId, title, id) + usersToTag;
    streamBody.push(postingMessage);
    if (rqstBody.yammerthreadid) {
      //it's a comment
      await yammerService.PostYammerComment(
        rqstBody.yammerthreadid,
        streamBody
      );
    } else if (requestId > 0) {
      //it's an existing request without yammerthreadid
      await createYammerThread(
        rqstBody,
        postingMessage,
        action === "Save Changes",
        usersToTag
      );
    } else {
      //it's a new request
      await yammerService.PostToYammer(
        id,
        requestIndex,
        "_doc",
        false,
        streamBody
      );
    }
  };
  const createYammerThread = async (
    rqstBody,
    commentMsg = null,
    isSaveChanges = null,
    usersToTag
  ) => {
    let title;
    let url;
    let rqstId;
    let requestIndex;
    let autoImplementAdmin;
    title = rqstBody.cleantitle;
    url = "/promote/how-to/request/";
    rqstId = rqstBody.howto_req_id;
    requestIndex = process.env.REACT_APP_HTREQUEST_IDX;
    autoImplementAdmin = isAdmin && !commentMsg ? " and implemented," : ",";

    let streamBody = [];
    let initialThreadMessage = "A new How-To request was submitted";
    initialThreadMessage += isSaveChanges ? "" : autoImplementAdmin;

    initialThreadMessage =
      initialThreadMessage +
      ", " +
      process.env.REACT_APP_SEARCHTOOLS_URL +
      url +
      rqstId +
      " (" +
      rqstId +
      " - " +
      title.replace('"', "&quot;") +
      "). " +
      usersToTag;
    streamBody.push(initialThreadMessage);
    streamBody.push(commentMsg);
    let postComment = commentMsg != null;
    await yammerService.PostToYammer(
      rqstId,
      requestIndex,
      "_doc",
      postComment,
      streamBody
    );
  };
  const PostCommentsInTheTracker = (request) => {
    let user = localStorage.getItem("searchtools.eid");
    let comments = [];
    let delegatesComments = [];
    request.user = user;

    if (request.action !== "Save Changes" || formHasUpdates()) {
      comments = CommentTracker.PostComments(request);
    }

    if (idFromUrl) {
      let delegatesHaveChanged =
        JSON.stringify(form.contacts) !==
        JSON.stringify(originalData.htReqData.contacts);

      if (form.delegates.length > 0 && delegatesHaveChanged) {
        let delegatesInfo = {
          originalDelegates: originalData.htReqData.contacts,
          newDelegates: form.contacts,
          user: request.user,
          formType: "How-To"
        };
        delegatesComments = CommentTracker.PostDelegatesComments(delegatesInfo);
        comments.unshift(delegatesComments);
      }
    }
    if (form.comments !== "") {
      comments.unshift(CommentTracker.SaveComment(form.comments, user));
    }
    return comments;
  };

  const formHasUpdates = () => {
    let oldTitle = JSON.stringify(originalData.formData.title);
    let newTitle = JSON.stringify(form.title);
    let oldURL = JSON.stringify(originalData.formData.url);
    let newURL = JSON.stringify(form.url);
    let oldSteps = JSON.stringify(originalData.formData.step);
    let newSteps = JSON.stringify(form.step);
    let oldLocation = JSON.stringify(originalData.formData.countries);
    let newLocation = JSON.stringify(form.countries);
    let oldRefinerPage = JSON.stringify(originalData.formData.aid);
    let newRefinerPage = JSON.stringify(form.aid);
    let oldKeywords = JSON.stringify(originalData.formData.keyword);
    let newKeyword = JSON.stringify(form.keyword);

    return (
      newTitle !== oldTitle ||
      newURL !== oldURL ||
      oldSteps !== newSteps ||
      oldLocation !== newLocation ||
      oldRefinerPage !== newRefinerPage ||
      oldKeywords !== newKeyword
    );
  };

  const saveHowTo = (action, status) => {
    setConfirmationModal({ ...confirmationModal, displayModal: false });
    setIsLoading(true);

    let howtoJsonBody = buildRequestJson(status, action);
    if(action === "Save Draft"){
      howtoJsonBody.status = "Draft";
    }
    
    let requestInfo = {
      action: action,
      status: status,
      requestId: null
    };
    store.services.howToService
      .SaveHowTo(howtoJsonBody, form.howto_req_id, form.howto_id)
      .then(async (r) => {
        // Request How-To
        // Save Changes
        // Cancel Request
        if (
          (action === "Request How-To" ||
            action === "Save Changes" ||
            action === "Cancel") &&
          !store.state.isSuperUser
        ) {
          store.setShowFeedbackBox(1);
        }
        if(action !== "Save Draft"){
          await postOnYammer(howtoJsonBody[1], action, r.data._id);
        }
        setIsLoading(false);

        requestInfo.requestId = r.data._id;

        setModal(requestInfo);
        modal.setOnClose(() => navigate("/myrequests"));
        modal.show();
      })
      .catch((error) => {
        store.setShowFeedbackBox(0);
        setIsLoading(false);
        requestInfo.action = "Error";
        setModal(requestInfo);
        modal.show();
      });
  };

  const setModal = (requestInfo) => {
    let popUpMsg;
    let msg = "";
    switch (requestInfo.action) {
      case "Implement":
        msg =
          "has been saved and implemented. You may verify its implementation on Search.";
        break;
      case "Request How-To":
        msg =
          'was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.';
        break;
      case "Save Changes":
        msg = "was updated";
        break;
      case "Remove":
        setConfirmationModalMessage("Do you want to remove this How-To Card?");
        msg = "was deleted";
        break;
      case "Save Draft":
        msg = "was updated";
        break;
      case "Error":
        popUpMsg = "Error has been encountered. Try again later.";
        modal.setMsg(popUpMsg);
        return;
      default:
        setConfirmationModalMessage("Do you want to cancel your request?");
        msg = `was ${requestInfo.status.toLowerCase()}`;
    }
    modal.setMsg(`How-To request ID #${requestInfo.requestId} ${msg}`);
  };

  const showCompareTable = () => {
    setDisplayCompareTable(true);
  };
  const closeComparisonTable = () => {
    setDisplayCompareTable(false);
  };

  const cancelOperation = () => {
    setConfirmationModal({ ...confirmationModal, displayModal: false });
  };

  const checkSubmit = (event, action, status) => {
    if (event.key === "Enter" || event.type === "click") {
      if (action === "Remove" || action === "Cancel") {
        let requestInfo = {
          action: action,
          status: status,
          requestId: form.howto_req_id
        };
        setModal(requestInfo);
        setConfirmationModal({
          displayModal: true,
          action: action,
          status: status
        });
      } else {
        saveHowTo(action, status);
      }
    }
  };

  const showPreview = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let previewBody = {
      cleantitle: form.title.trim(),
      cleanurl: form.url.trim(),
      cleansteps: form.step.map((s) => s.step.trim())
    };
    let htpreview = await store.services.howToService.GenerateHtmlContent(
      previewBody
    );
    try {
      setPreviewContent(JSON.parse(htpreview.data));
    } catch (error) {
      setPreviewContent(htpreview.data);
    }
    setIsLoading(false);
    openPreview();
  };

  let cancelButton = !isAdmin && 
                    (form.status === "New" || form.status === "Draft") && 
                    idFromUrl && 
  (
    <button
      className={`btn btn-lg btn-outline-danger red-form-btn how-to-buttons`}
      data-testid="tc-form-button"
      id="ht-cancel-request-button"
      onClick={(event) => {
        checkSubmit(event, "Cancel", "Cancelled");
      }}
      disabled={disableButton}
    >
      Cancel Request
    </button>
  );

  let rejectButton = isAdmin && form.status === "New" && idFromUrl && (
    <button
      className={`btn btn-lg btn-outline-danger red-form-btn how-to-buttons`}
      data-testid="ht-form-button"
      id="ht-reject-button"
      onClick={(event) => {
        checkSubmit(event, "Reject", "Not Approved");
      }}
      disabled={disableButton}
    >
      Reject
    </button>
  );
  let removeButton = form.status === "Implemented" && idFromUrl && (
    <button
      className={`btn btn-lg btn-outline-danger red-form-btn how-to-buttons`}
      data-testid="tc-form-button"
      id="tc-remove-request-button"
      onClick={(event) => {
        checkSubmit(event, "Remove", "Inactive");
      }}
      disabled={disableButton}
    >
      Remove
    </button>
  );
  let compareButton = form.status === "New" &&
    form.howto_id !== 0 &&
    form.howto_id !== null && (
      <button
        className={`btn btn-lg btn-primary  how-to-buttons`}
        data-testid="ht-form-button"
        id="compare-button"
        disabled={disableButton}
        onClick={() => {
          showCompareTable();
        }}
      >
        Compare
      </button>
    );
  let saveChangesButton = form.status !== "Inactive" && 
                          idFromUrl &&
                          form.status !== "Draft" && 
  (
    <button
      className={`btn btn-lg btn-primary owner-css-save-changes-btn how-to-buttons`}
      data-testid="tc-form-button"
      id="ht-save-changes-btn"
      onClick={(event) => {
        checkSubmit(event, "Save Changes", "New");
      }}
      disabled={disableButton}
    >
      Save Changes
    </button> 
  );
  let requestHowTo = ((form.status === "New" && !idFromUrl) || 
                         form.status === "Draft") &&
                      !isAdmin && 
  (
    <button
      data-testid="ht-request-btn"
      id="request_btn"
      className="ht_primaryBtn btn btn-primary"
      disabled={disableButton}
      onClick={(event) => {
        checkSubmit(event, "Request How-To", "New");
      }}
    >
      Request How-To
    </button>
  );

  let implementButton = isAdmin && (
    <button
      data-testid="ht-implement-btn"
      id="implement_btn"
      className="ht_primaryBtn btn btn-primary"
      disabled={disableButton}
      onClick={(event) => {
        checkSubmit(event, "Implement", "Implemented");
      }}
    >
      Implement
    </button>
  );

  let previewButton = (
    <button
      type="button"
      className={`btn btn-lg btn-secondary owner-css-save-changes-btn how-to-buttons`}
      data-testid="ht-preview-btn"
      id="ht-preview-btn"
      disabled={disableButton}
      onClick={(e) => showPreview(e)}
    >
      Preview
    </button>
  );
  let draftButton = !isAdmin && !isFormReadOnly && (
    <button
        className={"btn btn-lg btn-secondary how-to-buttons"}
        data-testid="ht-draft-btn"
        id="ht-draft-btn"
        onClick={(event) => checkSubmit(event, "Save Draft", "Draft")}
        disabled={disableDraftButton}
      >
        Save Draft
      </button>
  )
  return (
    <div className="form-group ht_btns_group">
      {cancelButton}
      {rejectButton}
      {removeButton}
      {previewButton}
      {draftButton}
      {compareButton}
      {saveChangesButton}
      {implementButton}
      {requestHowTo}
      {confirmationModal.displayModal && (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={() =>
            saveHowTo(confirmationModal.action, confirmationModal.status)
          }
          modal_msg={confirmationModalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          btn_2_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple btn-adjust-content-confirm-remove"
              : "btn btn-light-mode-purple btn-adjust-content-confirm-remove"
          }
          secondOption={true}
          firstOptText={`${confirmationModal.action} How-To`}
          onClick={() =>
            saveHowTo(confirmationModal.action, confirmationModal.status)
          }
          secondOptText={"Cancel"}
          noHeader={true}
          onClickSecondOpt={cancelOperation}
        />
      )}

      {displayCompareTable && (
        <ModalCustom
          darkMode={theme === "dark"}
          onClick={closeComparisonTable}
          onClose={closeComparisonTable}
          modal_msg={
            <CompareTable
              originalData={originalData.htData}
              currentData={form}
              closeComparisonTable={closeComparisonTable}
            />
          }
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize={"modal-xl"}
          noHeader={true}
        />
      )}
    </div>
  );
};

export default HtButtons;
