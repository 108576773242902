import ModalCustom from "../../../Modal/ModalCustom";
import { ThemeContext } from "../../../../ThemeContext";
import { useContext, useState } from "react";
import StoreContext from "../../../../store/storecontext";
import { useNavigate } from "react-router-dom";
import * as CommentTracker from "../../FormUI/CommentHistory/CommentTracker.js";

export const TopicCardButtons = ({
  isAdmin,
  disableButton,
  disableDraftButton,
  form,
  setIsLoading,
  callCompareTable,
  originalData,
  disableField,
  idFromUrl,
  openPreview,
  setPreviewContent,
  yammerService
}) => {
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openCancelRequestModal, setOpenCancelRequestModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const theme = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const primaryBtn =
    theme === "dark" ? "dark-mode-purple" : "light-mode-purple";
  const [cssSaveChangesBtn, setCssSaveChangesBtn] =
    useState("btn btn-primary ");

  const onClickRequestModal = () => {
    navigate("/myrequests");
    setOpenRequestModal(!openRequestModal);
  };

  const closeTheModal = () => {
    setOpenCancelRequestModal(!openCancelRequestModal);
  };

  const onClickCancelRequestModal = () => {
    closeTheModal();
    RemoveMethod("Inactive", false, "Remove Topic Card");
  };

  const formHasUpdates = () => {
    let originalLineItems = originalData.tcReqData.body;

    return (
      form.title !== originalData.tcReqData.title ||
      form.url !== originalData.tcReqData.url ||
      form.description !== originalData.tcReqData.description ||
      JSON.stringify(form.aid) !== JSON.stringify(originalData.tcReqData.aid) ||
      JSON.stringify(form.countries) !==
        JSON.stringify(originalData.tcReqData.country) ||
      JSON.stringify(form.keyword.sort()) !==
        JSON.stringify(originalData.tcReqData.keywordsraw.sort()) ||
      JSON.stringify(form.keywordsForReview) !==
        JSON.stringify(originalData.tcReqData.invalidkeywordsraw) ||
      JSON.stringify(form.supportContent) !==
        JSON.stringify(originalLineItems.getsupport) ||
      JSON.stringify(form.sitesNews) !==
        JSON.stringify(originalLineItems.newsandarticles) ||
      JSON.stringify(form.learningMaterials) !==
        JSON.stringify(originalLineItems.learn) ||
      JSON.stringify(form.salesMaterial) !==
        JSON.stringify(originalLineItems.salesmaterial) ||
      JSON.stringify(form.accenturePolicies) !==
        JSON.stringify(originalLineItems.accenturepolicies) ||
      JSON.stringify(form.internalResources) !==
        JSON.stringify(originalLineItems.internalresources) ||
      JSON.stringify(form.externalResources) !==
        JSON.stringify(originalLineItems.externalresources)
    );
  };

  const getSubmissionMessage = (status, id) => {
    let submissionMessage = "";
    let thisRequest = `Topic Card request ID #${id}`;
    if (status === "Implemented") {
      submissionMessage = `${thisRequest} has been saved and implemented. You may verify its implementation on Search.`;
    } else {
      submissionMessage = `${thisRequest} was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.`;
    }

    return submissionMessage;
  };

  const createYammerThread = async (
    rqstBody,
    commentMsg = null,
    isSaveChanges = null
  ) => {
    let requestType;
    let title;
    let requestId;
    let requestIndex;
    let usersToTag;
    let delegatesToPost =
      " [[user:" +
      form.delegates.map((delegate) => delegate.userid).join("]]  [[user:") +
      "]]";

    requestType = "Topic Card";
    title = rqstBody.title;
    requestId = rqstBody.tcrequestid;
    requestIndex = process.env.REACT_APP_TCREQUEST_IDX;
    let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;
    usersToTag =
      tagOwnerOnYammer +
      " " +
      (form.delegates.length > 0 ? delegatesToPost : "");

    let streamBody = [];

    let msg;
    let commentData = {
      tagOwnerOnYammer: tagOwnerOnYammer,
      delegatesToPost: form.delegates.length > 0 ? delegatesToPost : "",
      isAdmin: isAdmin,
      tcrequestid: requestId,
      title: title
    };

    if (isAdmin && !commentMsg) {
      msg = yammerService.TopicCardComments("admin-implement", commentData);
    } else {
      msg = yammerService.TopicCardComments("initial", commentData);
    }

    streamBody.push(msg);
    streamBody.push(commentMsg);
    let postComment = commentMsg != null;
    await yammerService.PostToYammer(
      requestId,
      requestIndex,
      "_doc",
      postComment,
      streamBody
    );
  };

  const postTopicCardInYammer = async (rqstBody, tcBody) => {
    let streamBody = [];

    let delegatesToPost =
      " [[user:" +
      form.delegates.map((delegate) => delegate.userid).join("]]  [[user:") +
      "]]";

    let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;
    let commentData = {
      tagOwnerOnYammer: tagOwnerOnYammer,
      delegatesToPost: rqstBody.contacts.length > 0 ? delegatesToPost : "",
      isAdmin: isAdmin,
      tcrequestid: rqstBody.tcrequestid,
      title: rqstBody.title
    };

    let commentMsg = yammerService.TopicCardComments("implement", commentData);
    //yAMMER Post when an Admin Implements a Topic Card Request
    if (
      !rqstBody.yammerthreadid &&
      rqstBody.creationdate === rqstBody.lastmodificationdate
    ) {
      let postMsg = yammerService.TopicCardComments("initial", commentData);

      streamBody.push(postMsg);
      streamBody.push(commentMsg);
      // PostToYammer(requestId, index, type, isI, rqstBody)
      yammerService
        .PostToYammer(
          rqstBody.tcrequestid,
          process.env.REACT_APP_TCREQUEST_IDX,
          "_doc",
          JSON.stringify(tcBody) !== "{}",
          streamBody
        )
        .then(() => {})
        .catch((error) => {
          console.error("Yammer thread couldn't be posted: " + error);
        });
    }
    //Yammer Post when an Admin Implements a Topic Card Request
    else if (rqstBody.yammerthreadid) {
      streamBody.push(commentMsg);
      yammerService
        .PostYammerComment(rqstBody.yammerthreadid, streamBody)
        .catch((error) => {
          console.error(
            "The comment couldn't be posted, method PostTopicCardInYammer: " +
              error
          );
        });
    } else {
      await createYammerThread(rqstBody);
    }
  };

  const postYammerTopicCardSaveChanges = async (rqstBody) => {
    let delegatesToPost =
      " [[user:" +
      form.delegates.map((delegate) => delegate.userid).join("]]  [[user:") +
      "]]";

    let tagOwnerOnYammer = `[[user:${rqstBody.submittedbyuserid}]]`;

    let commentData = {
      tagOwnerOnYammer: tagOwnerOnYammer,
      delegatesToPost: rqstBody.contacts.length > 0 ? delegatesToPost : "",
      isAdmin: isAdmin,
      tcrequestid: rqstBody.tcrequestid,
      title: rqstBody.title
    };
    let commentMsg = yammerService.TopicCardComments(
      "save-changes",
      commentData
    );

    let bodyComment = [];

    bodyComment.push(commentMsg);
    let arrStatus = ["Cancelled", "Not Approved", "Implemented", "New"];
    //Yammer Post when a User Updates a Topic Card Request
    if (formHasUpdates()) {
      if (rqstBody.yammerthreadid) {
        yammerService
          .PostYammerComment(rqstBody.yammerthreadid, bodyComment)
          .catch((error) => {
            console.error(
              "The comment couldn't be posted, method PostYammerComment: " +
                error
            );
          });
      } else if (arrStatus.includes(form.status)) {
        await createYammerThread(rqstBody, commentMsg, true);
      }
    }
  };

  const postRemoveTcCommentInYammer = (rqstBody, isTc) => {
    let body = [];
    let commentMessage = "";

    let tagOwnerOnYammer = `[[user:${form.submittedbyuserid}]]`;
    let delegatesToPost =
      " [[user:" +
      form.delegates.map((delegate) => delegate.userid).join("]]  [[user:") +
      "]]";
    let contacts = form.contacts || form.selectedeids;

    let commentData = {
      tagOwnerOnYammer: tagOwnerOnYammer,
      delegatesToPost: contacts.length > 0 ? delegatesToPost : "",
      isAdmin: isAdmin,
      tcrequestid: rqstBody.tcrequestid,
      title: rqstBody.title
    };
    switch (rqstBody.status) {
      case "Cancelled":
        commentMessage = yammerService.TopicCardComments("cancel", commentData);
        break;
      case "Inactive":
        commentMessage = yammerService.TopicCardComments("remove", commentData);
        break;
      case "Not Approved":
        commentMessage = yammerService.TopicCardComments("reject", commentData);
        break;
    }

    if (isTc) {
      body.push(commentMessage);
      if (!rqstBody.yammerthreadid) {
        createYammerThread(rqstBody, commentMessage);
      } else {
        yammerService
          .PostYammerComment(rqstBody.yammerthreadid, body)
          .catch((error) => {
            console.error("The comment couldn't be posted: " + error);
          });
      }
    } else {
      body.push(commentMessage);
      yammerService
        .PostYammerComment(rqstBody.yammerthreadid, body)
        .catch((error) => {
          console.error(
            "The comment couldn't be posted, method PostYammerComment: " + error
          );
        });
    }
  };

  const SaveChanges = async (action, event) => {
    event.preventDefault();
    setIsLoading(true);
    let currentUTCDate = new Date().toISOString();
    let body = [];

    if (action === "Save Changes" && !store.state.isSuperUser) {
      store.setShowFeedbackBox(1);
    }

    if (!disableButton) {
      //Topic Card Request Status History
      let oldStatus = form.status; 
      let rqstBody = await buildRequestJson(form.status, action);
      //the draft will not change status when updated
      if(action === "Save Draft"){
        rqstBody.status = "Draft";
      }

      rqstBody.history.push({
        status: rqstBody.status,
        user: sessionStorage.getItem("searchtools.eid"),
        dateUTC: currentUTCDate
      });

      body.push({});
      body.push(rqstBody);

      let r = null;
      try {
        r = await store.services.topicCardService.SaveTopicCard(
          body,
          form.tcrequestid,
          form.tcid
        );
      } catch (error) {
        store.setShowFeedbackBox(0);
      }

      postYammerTopicCardSaveChanges(rqstBody);

      setModalMessage(
        "Topic Card request ID # " + r.data._id + " was updated."
      );
      setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    }
  };

  const submitTopicCard = async (status, event) => {
    let action = event.target.innerHTML;
    if (event.type === "click") {
      setIsLoading(true);

      let topiccard = await buildRequestJson(status, action);
      if(action === "Save Draft"){
        topiccard.status = "Draft";
        topiccard.wasdraft = true;
      }else if (action === "Request Topic Card"){
        topiccard.status = "New";
      }

      if (action === "Request Topic Card" && !store.state.isSuperUser) {
        store.setShowFeedbackBox(1);
      }

      let tcBody = {};
      if (status === "Implemented") {
        let gs = form.supportContent.map((r) => r.url.trim());
        let lrn = form.learningMaterials.map((r) => r.url.trim());
        let nar = form.sitesNews.map((r) => r.url.trim());
        let ap = form.accenturePolicies.map((r) => r.url.trim());
        let ir = form.internalResources.map((r) => r.url.trim());
        let er = form.externalResources.map((r) => r.url.trim());
        let sls = form.salesMaterial.map((r) => r.url.trim());

        const urls = [
          ...gs.filter((r) => r !== ""),
          ...lrn.filter((r) => r !== ""),
          ...nar.filter((r) => r !== ""),
          ...ap.filter((r) => r !== ""),
          ...ir.filter((r) => r !== ""),
          ...er.filter((r) => r !== ""),
          ...sls.filter((r) => r !== "")
        ];

        //Collect all Titles

        gs = form.supportContent.map((r) => r.title.trim());
        lrn = form.learningMaterials.map((r) => r.title.trim());
        nar = form.sitesNews.map((r) => r.title.trim());
        ap = form.accenturePolicies.map((r) => r.title.trim());
        ir = form.internalResources.map((r) => r.title.trim());
        er = form.externalResources.map((r) => r.title.trim());
        sls = form.salesMaterial.map((r) => r.title.trim());

        const titles = [
          ...gs.filter((r) => r !== ""),
          ...lrn.filter((r) => r !== ""),
          ...nar.filter((r) => r !== ""),
          ...ap.filter((r) => r !== ""),
          ...ir.filter((r) => r !== ""),
          ...er.filter((r) => r !== ""),
          ...sls.filter((r) => r !== "")
        ];

        let preview = await store.services.topicCardService.GenerateHtmlContent(
          topiccard
        );

        tcBody = {
          sthccontent: "",
          sthcsearchterms: topiccard.keywords,
          sthcAID: topiccard.aid,
          cleancountry: [...topiccard.country],
          urls: urls,
          titles: titles,
          contacts: form.contacts,
          cleanstatus: "Active",
          title: form.title.trim(),
          cleanurl: form.url.trim(),
          cleandescription: form.description.trim(),
          cleankeywords: topiccard.keywords,
          cleankeywordsraw: topiccard.keywordsraw,
          cleaninvalidkeywords: topiccard.invalidkeywords,
          cleaninvalidkeywordsraw: topiccard.invalidkeywordsraw,
          creationdate: topiccard.creationdate,
          template: topiccard.template,
          delegates: form.delegates
        };

        try {
          tcBody.sthccontent = JSON.parse(preview.data);
        } catch (error) {
          tcBody.sthccontent = preview.data;
        }
      }
      let body = [tcBody, topiccard];

      let SaveTcOnIndex = null;

      try {
        SaveTcOnIndex = await store.services.topicCardService.SaveTopicCard(
          body,
          parseInt(form.tcrequestid),
          parseInt(topiccard.tcid)
        );
      } catch (error) {
        console.log("error saving tc data");
        store.setShowFeedbackBox(0);
      }

      let newReqId = SaveTcOnIndex.data._id;

      topiccard.tcrequestid = newReqId;

      await postTopicCardInYammer(topiccard, tcBody);

      let submissionMessage = getSubmissionMessage(status, newReqId);
      if(action === "Save Draft"){
        submissionMessage = `Draft ${newReqId} has been saved successfully. Upon clicking OK, you will be redirected to the Manage my Request section where you can track the status of this request.`;
      }
      setModalMessage(submissionMessage);
      setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    }
  };
  const buildRequestJson = async (status, action = null) => {
    let user = localStorage.getItem("searchtools.eid");
    let currentDateTime = new Date();
    let currentUTCDate = currentDateTime.toISOString();
    const creationDate = form.creationdate
      ? form.creationdate
      : currentDateTime;
    //Checks if the form was updated in status Cancelled and Not Approved
    let isCancelledOrNotApproved =
      form.status === "Cancelled" || form.status === "Not Approved";

    let currentStatus = form.status;
    //Checks if essential updates were performed in the Topic Card Form (it's combined with the previous condition)
    if (isCancelledOrNotApproved && formHasUpdates()) {
      status = "New";
    } else if (form.tcrequestid && !formHasUpdates()) {
      status = currentStatus;
    } else if(action === "Request Topic Card"){
      status = "New";
    } else {
      status = action === "Save Changes" ? "New" : form.status;
    }

    let submittedBY = "";
    let submittedByPk = "";
    let currentUserId = null;

    if (
      form.submittedbyenterpriseid !== null &&
      form.submittedbyenterpriseid.length > 0
    ) {
      submittedBY = form.submittedbyenterpriseid;
      submittedByPk = form.submittedbypeoplekey;
      currentUserId = await store.services.yammerService.GetUserIdYammer(
        form.submittedbyenterpriseid
      );
    } else {
      submittedBY = user;
      submittedByPk = localStorage.getItem("searchtools.pk");
      currentUserId = await store.services.yammerService.GetUserIdYammer(user);
    }

    let rqstBody = {
      title: form.title.trim(),
      url: form.url.trim(),
      description: form.description.trim(),
      body: {
        getsupport: form.supportContent.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        learn: form.learningMaterials.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        newsandarticles: form.sitesNews.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        accenturepolicies: form.accenturePolicies.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        internalresources: form.internalResources.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        externalresources: form.externalResources.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        salesmaterial: form.salesMaterial.filter(
          (r) => r.title.trim() !== "" && r.url.trim() !== ""
        ),
        getintouch: form.getInTouch
      },
      aid: form.aid,
      keywords: form.keyword.map((kw) => {
        return kw.toLowerCase();
      }),
      keywordsraw: form.keyword,
      invalidkeywords: form.keywordsForReview.map((kw) => {
        return kw.toLowerCase();
      }),
      invalidkeywordsraw: form.keywordsForReview,
      contacts: form.contacts,
      comments: form.arrComments,
      lastmodificationdate: currentUTCDate,
      creationdate: creationDate,
      history: form.history,
      expirationdate: null,
      status: status,
      submittedbyenterpriseid: submittedBY,
      submittedbypeoplekey: submittedByPk,
      submittedbyuserid: currentUserId.data,
      country: form.countries,
      delegates: form.delegates,
      organization: form.organization,
      tcid: form.tcid,
      yammerthreadid: form.yammerthreadid,
      tcrequestid: form.tcrequestid,
      template: "standard",
      wasdraft : form.wasdraft
    };
    rqstBody.history.push({
      status: status,
      user: localStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });
    let request = {
      action: action,
      status: action === "Implement" ? "Implemented" : status,
      reqType: null,
      requestId: idFromUrl
    };

    if (!idFromUrl) {
      rqstBody.comments = PostCommentsInTheTracker(request);
    } else if (request.action === "Save Changes") {
      if (formHasUpdates()) {
        rqstBody.comments = PostCommentsInTheTracker(request).concat(
          rqstBody.comments
        );
      } else {
        let user = localStorage.getItem("searchtools.eid");
        if (form.comments !== "") {
          rqstBody.comments.unshift(
            CommentTracker.SaveComment(form.comments, user)
          );
        }
      }
    } else {
      rqstBody.comments = PostCommentsInTheTracker(request).concat(
        rqstBody.comments
      );
    }
    return rqstBody;
  };

  const PostCommentsInTheTracker = (request) => {
    let user = localStorage.getItem("searchtools.eid");
    let comments = [];
    let delegatesComments = [];
    request.user = user;

    comments = CommentTracker.PostComments(request);
    if (idFromUrl) {
      let delegatesHaveChanged =
        JSON.stringify(form.contacts) !==
        JSON.stringify(originalData.tcReqData.contacts);

      if (form.delegates.length > 0 && delegatesHaveChanged) {
        let delegatesInfo = {
          originalDelegates: originalData.tcReqData.contacts,
          newDelegates: form.contacts,
          user: request.user,
          formType: "Topic Card"
        };
        delegatesComments = CommentTracker.PostDelegatesComments(delegatesInfo);
        comments.unshift(delegatesComments);
      }
    }

    if (form.comments !== "") {
      comments.unshift(CommentTracker.SaveComment(form.comments, user));
    }
    return comments;
  };

  // called by the modal and the cancel/remove buttons
  const RemoveMethod = async (status, isCustomTc, event) => {
    let action = !event.target ? event : event.target.innerHTML;
    let currentUTCDate = new Date().toISOString();
    let tcRequest = {};
    let body = [];

    setIsLoading(true);

    //Topic Card Request Status History
    form.history.push({
      status: status,
      user: sessionStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });

    tcRequest.doc = {};
    tcRequest.doc.title = form.title;
    tcRequest.doc.tcrequestid = form.tcrequestid;
    tcRequest.doc.lastmodificationdate = currentUTCDate;
    tcRequest.doc.expirationdate = currentUTCDate;
    tcRequest.doc.comments = form.arrComments;
    tcRequest.doc.history = form.history;
    tcRequest.doc.yammerthreadid = form.yammerthreadid;
    tcRequest.doc.contacts = form.selectedeids;
    tcRequest.doc.submittedbyenterpriseid = form.submittedbyenterpriseid;
    tcRequest.doc.status = status;
    let request = {
      action: action,
      status: status,
      reqType: null,
      requestId: idFromUrl
    };

    if (!idFromUrl) {
      tcRequest.doc.comments = PostCommentsInTheTracker(request);
    } else {
      tcRequest.doc.comments = PostCommentsInTheTracker(request).concat(
        tcRequest.doc.comments
      );
    }

    if (status === "Inactive") {
      let tc = {
        doc: {
          cleanstatus: "Inactive"
        }
      };
      body.push(tc);
    } else {
      let tc = {
        doc: {}
      };
      body.push(tc);
    }

    body.push(tcRequest);

    let r = null;
    try {
      r = await store.services.topicCardService.UpdateTopicCard(
        body,
        form.tcrequestid,
        form.tcid
      );
    } catch (error) {
      store.setShowFeedbackBox(0);
    }

    if (!isCustomTc) {
      postRemoveTcCommentInYammer(tcRequest.doc, true);
    }

    let modalMessage = "";
    if (status === "Inactive") {
      modalMessage = "Topic Card has been deleted.";
    } else {
      modalMessage =
        "Topic Card" +
        " request ID # " +
        r.data._id +
        " was " +
        status.toLowerCase();
    }

    setModalMessage(modalMessage);
    setIsLoading(false);
    setOpenRequestModal(!openRequestModal);

    setIsLoading(false);
  };

  const RemoveTc = (pStatus, pIsCustomTc, event) => {
    event.preventDefault();
    if (pStatus === "Inactive") {
      setModalMessage("Do you want to remove this Topic Card? ");
      setOpenCancelRequestModal(!openCancelRequestModal);
    } else {
      if (pStatus === "Cancelled" && !store.state.isSuperUser) {
        store.setShowFeedbackBox(1);
      }
      RemoveMethod(pStatus, pIsCustomTc, event);
    }
  };

  const saveDraft = (e)=>{
    e.preventDefault();
    if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0){
      SaveChanges("Save Draft",e);
    }else{
      submitTopicCard("Draft", e);
    }
  }

  const showCompareTable = (e) => {
    e.preventDefault();
    callCompareTable();
  };
  const showPreview = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let previewBody = await buildRequestJson("New");
    let tcpreview = await store.services.topicCardService.GenerateHtmlContent(
      previewBody
    );

    try {
      setPreviewContent(JSON.parse(tcpreview.data));
    } catch (error) {
      setPreviewContent(tcpreview.data);
    }
    setIsLoading(false);
    openPreview();
  };

  let htmlFormButtons = {
    previewBtn: (
      <button
        className="btn btn-lg btn-secondary tc-btn-form-styles"
        data-testid="preview-topic-card"
        id="tc-preview-button"
        disabled={disableButton}
        onClick={(e) => showPreview(e)}
      >
        Preview
      </button>
    ),
    saveChangesAdminBtn: (
      <button
        className={`btn btn-lg btn-outline-${primaryBtn} tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-save-changes-btn"
        onClick={(event) => {
          SaveChanges("Save Changes", event);
        }}
        disabled={disableButton}
      >
        Save Changes
      </button>
    ),
    saveChangesRegularBtn: (
      <button
        className={`btn btn-lg btn-primary owner-css-save-changes-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-save-changes-owner-btn"
        onClick={(event) => {
          SaveChanges("Save Changes", event);
        }}
        disabled={disableButton}
      >
        Save Changes
      </button>
    ),
    implementBtn: (
      <button
        className={`btn btn-lg btn-primary btn-${primaryBtn} tc-btn-form-styles`}
        data-testid="implement-topic-card"
        id="tc-implement-btn"
        onClick={(event) => submitTopicCard("Implemented", event)}
        disabled={disableButton}
      >
        Implement
      </button>
    ),
    requestTopicCardBtn: (
      <button
        className={`btn btn-lg btn-primary btn-${primaryBtn} tc-btn-form-styles`}
        data-testid="request-topic-card"
        id="tc-request-button"
        onClick={(event) => submitTopicCard("New", event)}
        disabled={disableButton}
      >
        Request Topic Card
      </button>
    ),
    rejectBtn: (
      <button
        className={`btn btn-lg btn-outline-danger red-form-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-reject-button"
        onClick={(event) => {
          RemoveTc("Not Approved", false, event);
        }}
        disabled={disableButton}
      >
        Reject
      </button>
    ),
    cancelRequestBtn: (
      <button
        className={`btn btn-lg btn-outline-danger red-form-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-cancel-request-button"
        onClick={(event) => {
          RemoveTc("Cancelled", false, event);
        }}
        disabled={disableButton}
      >
        Cancel Request
      </button>
    ),
    removeRequestBtn: (
      <button
        className={`btn btn-lg btn-outline-danger red-form-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-remove-request-button"
        onClick={(event) => {
          RemoveTc("Inactive", false, event);
        }}
        disabled={disableButton}
      >
        Remove
      </button>
    ),
    compareBtn: (
      <button
        className={`btn btn-lg btn-outline-${primaryBtn} tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-compare-table-button"
        disabled={disableButton}
        onClick={(e) => showCompareTable(e)}
      >
        Compare
      </button>
    ),saveDrafBtn: (
      <button
        className={"btn btn-lg btn-secondary tc-btn-form-styles"}
        data-testid="tc-form-button"
        id="tc-save-draft-btn"
        onClick={(event) => {
          saveDraft(event);
        }}
        disabled={disableDraftButton}
      >
        Save Draft
      </button>
    )
  };

  let showRejectButton = false;
  let showCompareButton = false;
  let showPreviewButton = true;
  let showSaveChangesButton = false;
  let showCancelRequestButton = false;
  let showRequestTcButton = false;
  let showRemoveButton = false;

  let currEid = localStorage.getItem("searchtools.eid");
  let isOwner = currEid === form.submittedbyenterpriseid;
  let isDelegate = form.contacts.includes(currEid);

  if (isAdmin) {
    switch (form.status) {
      case "Implemented":
        showRemoveButton = true;
        // showPreviewButton= true;
        showSaveChangesButton = true;
        break;

      case "New":
        if (parseInt(form.tcid) > 0) {
          showRejectButton = true;
          showCompareButton = true;
          // showPreviewButton = true;
          showSaveChangesButton = true;
        } else if (
          parseInt(form.tcid) <= 0 &&
          parseInt(form.tcrequestid) > 0 &&
          parseInt(idFromUrl) > 0
        ) {
          showRejectButton = true;
          // showPreviewButton = true;
          showSaveChangesButton = true;
        }
        break;

      case "Cancelled":
        //  showPreviewButton = true;
        showSaveChangesButton = true;
        break;

      case "Not Approved":
        //  showPreviewButton = true;
        showSaveChangesButton = true;
        break;

      default:
        break;
    }
  } else if (!isAdmin) {
    switch (form.status) {
      case "Implemented":
        if (isOwner || isDelegate) {
          showRemoveButton = true;
          // showPreviewButton= true;
          showSaveChangesButton = true;
        }
        break;

      case "New":
        if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) <= 0) {
          showRequestTcButton = true;
        } else if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) > 0) {
          if (isOwner || isDelegate) {
            showCancelRequestButton = true;
            // showPreviewButton= true;
            showSaveChangesButton = true;
          }
        } else if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0) {
          if (isOwner || isDelegate) {
            showCancelRequestButton = true;
            showCompareButton = true;
            // showPreviewButton= true;
            showSaveChangesButton = true;
          }
        }
        break;

      case "Draft":
        showRequestTcButton = true;
        if (parseInt(form.tcid) <= 0 && parseInt(form.tcrequestid) > 0) {
          if (isOwner || isDelegate) {
            showCancelRequestButton = true;
            // showPreviewButton= true;
            // showSaveChangesButton = true;
          }
        } else if (parseInt(form.tcid) > 0 && parseInt(form.tcrequestid) > 0) {
          if (isOwner || isDelegate) {
            showCancelRequestButton = true;
            showCompareButton = true;
            // showPreviewButton= true;
            // showSaveChangesButton = true;
          }
        }
        
        break;
      case "Cancelled":
        //  showPreviewButton = true;
        showSaveChangesButton = true;
        break;

      case "Not Approved":
        //  showPreviewButton = true;
        showSaveChangesButton = true;
        break;

      default:
        break;
    }
  }

  let adminButtons = (
    <div className="tc-form-buttons-div">
      {showRejectButton ? htmlFormButtons.rejectBtn : <></>}
      {showRemoveButton ? htmlFormButtons.removeRequestBtn : <></>}
      {/* ---------------- */}
      {showCompareButton ? htmlFormButtons.compareBtn : <></>}
      {showPreviewButton ? htmlFormButtons.previewBtn : <></>}
      {showSaveChangesButton ? htmlFormButtons.saveChangesAdminBtn : <></>}
      {htmlFormButtons.implementBtn}
    </div>
  );

  let regularUserButtons = (
    <div className="tc-form-buttons-div">
      {showRemoveButton ? htmlFormButtons.removeRequestBtn : <></>}
      {showCancelRequestButton ? htmlFormButtons.cancelRequestBtn : <></>}
      {/* ---------------- */}
      {showCompareButton ? htmlFormButtons.compareBtn : <></>}
      {showPreviewButton ? htmlFormButtons.previewBtn : <></>}
      {htmlFormButtons.saveDrafBtn}
      {showSaveChangesButton ? htmlFormButtons.saveChangesRegularBtn : <></>}
      {showRequestTcButton ? htmlFormButtons.requestTopicCardBtn : <></>}
    </div>
  );

  return (
    <>
      {openRequestModal ? (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={onClickRequestModal}
          modal_msg={modalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      ) : (
        <div></div>
      )}
      {openCancelRequestModal ? (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={onClickCancelRequestModal}
          modal_msg={modalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple btn-adjust-content-cancel"
              : "btn btn-light-mode-purple btn-adjust-content-cancel"
          }
          btn_2_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple btn-adjust-content-confirm-remove"
              : "btn btn-light-mode-purple btn-adjust-content-confirm-remove"
          }
          modalSize=""
          noHeader={true}
          secondOption={true}
          firstOptText={"Remove Topic Card"}
          onClick={onClickCancelRequestModal}
          secondOptText={"Cancel"}
          onClickSecondOpt={closeTheModal}
        />
      ) : (
        <div></div>
      )}
      {disableField ? (
        <> </>
      ) : (
        <div className="topic-card-form-buttons-div">
          {isAdmin ? adminButtons : regularUserButtons}
        </div>
      )}
    </>
  );
};
