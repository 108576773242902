


import { formatDate } from "../../FormatDate/DateFormat";

// this component displays the form status info when loading a request
// the information is diplayed at the top of the form.
// there are 2 types of headers that will appear depending on which form and request 
// is being loaded.
const FormInfoHeader = ({
    reqType,
    formName,
    requestId,
    lastRequestedBy,
    formRequestType,
    formStatus,
    cleanCreationdate,
    showLastModified,
    showSecondSection,
    showInfoHeader})=>{

    let tempCopy = [...lastRequestedBy];
    let lastRequestedByEid = "...";
    if(tempCopy.length > 0){
        lastRequestedByEid = tempCopy.pop().user;
    }

    const infoHeaderJsx = (
        <div
            id="request-info-div-update"
            className="request-info-parent-div"
        >
            <div className={`${reqType}-info update-info request-info-title-div`}>
                <div>
                    {`${formName} Request ID `}<strong style={{marginLeft:'3px'}} >{requestId}</strong>
                </div>
                {showLastModified ? 
                    <div>
                        Last updated by:&nbsp;<strong>{lastRequestedByEid}</strong>
                    </div> : <></>
                }
            </div>
            <div>
                {showSecondSection ?
                    <div className="request-info-child-div">
                    <div className="request-info-block">
                        Request type:&nbsp;<strong>{formRequestType}</strong>
                    </div>
                    <div className="request-info-block">
                        Status:&nbsp;<strong>{formStatus}</strong>
                    </div>
                    <div className="request-info-block">
                        Submit time:&nbsp;<strong>{formatDate(cleanCreationdate)}</strong>
                    </div>
                    </div>
                    : <></>
                }
            </div>
        </div>
    );

    return (
        <div>
            {showInfoHeader ? infoHeaderJsx : <></>}
        </div>
    );
}

export default FormInfoHeader;