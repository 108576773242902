import BaseService from "./BaseService";

class GenIAService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  
  GetGenIADescription(characterLimit, url){
    let self = this;
    return self.axios.post(`${self.baseUrl}getaisummary`, 
        {
            character_limit: characterLimit, 
            url: url
        },
        this.config);
  }

  GetGenIAKeywords(requestedKeywords, url){
    let self = this;
    return self.axios.post(`${self.baseUrl}getaikeywords`, 
        {
          requested_keywords: requestedKeywords, 
          url: url
        },
        this.config);
  }

  GetCrawledData(url) {
    return this.axios.post(
      `${this.baseUrl}getcrawledcontent`,
      { url: url },
      this.config
    );
  }
}
export default GenIAService;