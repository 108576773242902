import React, { useState, useEffect, useContext } from 'react';
import ModalCustom from '../Modal/ModalCustom';
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import TimerModal from './TimerModal';
import { ThemeContext } from '../../ThemeContext';

const SessionHandler = ({ getAppData, store, userDataReady, setUserDataReady }) => {
    const { accounts,instance } = useMsal();
    const { acquireToken } = useMsalAuthentication();
    
    const [sessionExpired, setSessionExpired] = useState(false);
    const [isTokenExpiringSoon, setIsTokenExpiringSoon] = useState(false);
    const themeCtx = useContext(ThemeContext);
    const theme = themeCtx[0];
    let tokenCheck;

    const checkTokenExpiration = () => {
        const timeLeft = getTokenTimeLeft();
        const fiveMinutes = 5 * 60 * 1000; 
        if (timeLeft < fiveMinutes) {
          if(timeLeft < 0){
            setSessionExpired(true);
          } else {
            setIsTokenExpiringSoon(true);
          }
        } else  {
          setIsTokenExpiringSoon(false);
        }
    };
 
    const setTokenForServices = (token) => {
        store.services.searchToolsService.setToken(token);
        store.services.bestBetService.setToken(token);
        store.services.howToService.setToken(token);
        store.services.topicCardService.setToken(token);
        store.services.yammerService.setToken(token);
        store.services.urlCheckerService.setToken(token);
        store.services.synonymsService.setToken(token);
        store.services.metricsFeedbackService.setToken(token);
        store.services.genIAService.setToken(token);
        store.services.reportingService.setToken(token);
        store.services.activeContentService.setToken(token);
    };

    const configureApp = async (accountData) => {
        if (accountData.length > 0) {
          const currentAccount = accountData[0];
    
          let usrEID = currentAccount.username.split("@")[0];
          let usrPk = currentAccount.idTokenClaims.peopleKey;
          let exp = currentAccount.idTokenClaims.exp;
          let token = "";
          let storageItemName =
            currentAccount.homeAccountId +
            "-" +
            currentAccount.environment +
            "-idtoken-" +
            process.env.REACT_APP_ID +
            "-" +
            currentAccount.tenantId +
            "-";
    
          try {
            let storedObjStr = sessionStorage.getItem(storageItemName);
    
            if (storedObjStr !== "" || undefined) {
              let tempObj = JSON.parse(storedObjStr);
              token = tempObj.secret;
            }
          } catch (error) {
            storageItemName += "--";
            let storedObjStr = sessionStorage.getItem(storageItemName);
    
            if (storedObjStr !== "" || undefined) {
              let tempObj = JSON.parse(storedObjStr);
              token = tempObj.secret;
            }
          }
    
          localStorage.setItem("searchtools.eid", usrEID);
          localStorage.setItem("searchtools.pk", usrPk);
    
          //set token
          sessionStorage.setItem(
            "searchtools." + process.env.REACT_APP_ID + ".token",
            token
          );
    
          //set exp
          sessionStorage.setItem(
            "searchtools.expiration.key" + process.env.REACT_APP_ID,
            exp
          );
        }
    };

    const startAppConfigs = async () => {
        await configureApp(accounts);
        let currToken = sessionStorage.getItem(
          "searchtools." + process.env.REACT_APP_ID + ".token"
        );
    
        //set token for services
        setTokenForServices(currToken);
    
        let currPK = localStorage.getItem("searchtools.pk");
        let currEid = localStorage.getItem("searchtools.eid");
    
        if (currPK === undefined || currPK === "undefined") {
          let userData = await store.services.searchToolsService.GetStUserData(
            currEid
          );
    
          if (userData.data.hits.hits.length > 0) {
            let userPK = userData.data.hits.hits[0]._source.pk;
            localStorage.setItem("searchtools.pk", userPK);
          }
        }
    
        // //check for user current role
        await getAppData();
        // value doesn't matter, the state used as a trigger
        setUserDataReady(true);
    };
    const onClickTimerModal = async () => {
      setIsTokenExpiringSoon(false)
      await refreshToken();
    };  
    const refreshToken = async () => {
        acquireToken(InteractionType.Silent,{account:accounts[0]}).then((res) => {
          instance.setActiveAccount(res.account);
          setTokenForServices(res.idToken);
          configureApp([res.account]);
          setIsTokenExpiringSoon(false);
        });
    };
    const timedOut = () =>{
      clearInterval(tokenCheck);
      setIsTokenExpiringSoon(false);
      setSessionExpired(true);
    }
    const getTokenTimeLeft = () => {
    let expirationKey = sessionStorage.getItem(
      "searchtools.expiration.key" + process.env.REACT_APP_ID
    );
     let expKeyNum = parseInt(expirationKey);

     let expDate = new Date(expKeyNum * 1000);

    let currDate = new Date();

    return expDate - currDate;
    }

    const sessionExpiredModal = (
                  <ModalCustom
                    darkMode={theme === "dark"}
                    onClick={()=>instance.logout()}
                    onClose={()=>instance.logout()}
                    modal_title={"Session expired"}
                    modal_msg={"Session expired. Please log in again."}
                    secondOption={false}
                    btn_1_class={
                      theme === "dark"
                        ? "btn btn-dark-mode-purple"
                        : "btn btn-light-mode-purple"
                    }
                  />
    );

    useEffect(() => {
        if (!userDataReady) {
          startAppConfigs();
        }
    }, []);

    useEffect(() => {
      //Each time sessionExpired is set to false, an interval is set
      // to check if the token is about to expire
      tokenCheck = setInterval(() => {
       if (!sessionExpired) {
         checkTokenExpiration();
       }
     }, 10000);
  
     return () => {
       clearInterval(tokenCheck);
     };
   }, [sessionExpired]);

    return (
        <>
          { 
            <div>
              {isTokenExpiringSoon && 
              <TimerModal
                onClick={onClickTimerModal}
                timeLeft={"05:00"}
                theme={theme}
                onTimeout={timedOut}
              />}
              {sessionExpired && sessionExpiredModal}
            </div>
          }
       </>
      );
};

export default SessionHandler;