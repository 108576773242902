import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CommentTracker from "../FormUI/CommentHistory/CommentTracker.js"

import ModalCustom from "../../Modal/ModalCustom";

const CTCButtons = (props, loadedDescriptionData) => {
  const [modalMessage, setModalMessage] = useState("");
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openCancelRequestModal, setOpenCancelRequestModal] = useState(false);
  const navigate = useNavigate();

  const onClickRequestModal = () => {
    navigate("/myrequests");
    setOpenRequestModal(!openRequestModal);
  };

  const primaryBtn = props.isDarkMode
    ? "dark-mode-purple"
    : "light-mode-purple";

  const getDataFromDom = () => {
    // title
    const TITLE_ID = "ctc-title-input";
    let htmltTitle = document.querySelector(`#${TITLE_ID}`);
    let titleValue = htmltTitle.value;

    // url
    const URL_ID = "ctc-url-input";
    let htmlUrl = document.querySelector(`#${URL_ID}`);
    let urlValue = htmlUrl.value;

    // countries
    const COUNTRY_ID = "country-name-on-list";
    let countriesHtml = document.querySelectorAll(`#${COUNTRY_ID}`);
    let countriesArr = [];
    for (let i = 0; i < countriesHtml.length; i++) {
      countriesArr.push(countriesHtml[i].innerText);
    }

    // refiner pages
    const REFINER_ID = "refiner-name-on-list";
    let refinerHtml = document.querySelectorAll(`#${REFINER_ID}`);
    let refinersArr = [];
    for (let i = 0; i < refinerHtml.length; i++) {
      refinersArr.push(refinerHtml[i].innerText);
    }

    // green keywords
    let greenKwHTMLlist = document.querySelectorAll(".green-kw-class");
    let greenKwList = [];
    for (let r = 0; r < greenKwHTMLlist.length; r++) {
      const kw = greenKwHTMLlist[r].innerHTML;
      greenKwList.push(kw);
    }

    // red keywords
    let redKwHTMLlist = document.querySelectorAll(".red-kw-class");
    let redKwList = [];
    for (let q = 0; q < redKwHTMLlist.length; q++) {
      const kw = redKwHTMLlist[q].innerHTML;
      redKwList.push(kw);
    }

    // comment
    const COMMENT_ID = "ctc-comments-input";
    let commentHtml = document.querySelector(`#${COMMENT_ID}`);
    let commentValue = commentHtml.value;

    let data = {
      title: titleValue,
      url: urlValue,
      countries: countriesArr,
      refiners: refinersArr,
      greenKw: greenKwList,
      redKw: redKwList,
      comment: commentValue,
      arrComments: []
    };
    return data;
  };

  const formHasChanges = () => {
    let dataFromDom = getDataFromDom();

    return (
      dataFromDom.title !== props.formObjData.loadedtitle ||
      dataFromDom.url !== props.formObjData.loadedurl ||
      JSON.stringify(props.formDescription) !==
        props.formObjData.loadeddescription ||
      JSON.stringify(dataFromDom.countries) !==
        JSON.stringify(props.formObjData.loadedcountries) ||
      JSON.stringify(dataFromDom.greenKw) !==
        JSON.stringify(props.formObjData.loadedgreenKw) ||
      JSON.stringify(dataFromDom.redKw) !==
        JSON.stringify(props.formObjData.loadedredKw) ||
      JSON.stringify(dataFromDom.refiners) !==
        JSON.stringify(props.formObjData.loadedrefiners)
    );
  };

  const BuildRequestJson = (status, action) => {
    let user = localStorage.getItem("searchtools.eid");
    let currentDateTime = new Date();
    let currentUTCDate = currentDateTime.toISOString();
    let dataFromDom = getDataFromDom();
    let formContacts = [];
    let isCancelledOrNotApproved =
      props.formObjData.status === "Cancelled" ||
      props.formObjData.status === "Not Approved";
    let currentStatus = props.formObjData.status;

    //Checks if essential updates were performed in the Topic Card Form (it's combined with the previous condition)
    if (isCancelledOrNotApproved && formHasChanges()) {
      status = "New";
    } else if (
      props.formObjData.tcid &&
      props.formObjData.tcrequestid &&
      !formHasChanges()
    ) {
      status = currentStatus;
    } else if (!formHasChanges()) {
      status = currentStatus
    } else {
      status = action === "Save Changes" ? "New" : props.formObjData.status;
    }
    //User Comment added when the Request is populated
    if (dataFromDom.comment && dataFromDom.comment.trim().length > 0) {
      props.formObjData.arrComments.unshift({
        comment: dataFromDom.comment.trim(),
        user: user,
        dateUTC: currentUTCDate
      });
    }
    for (let d = 0; d < props.formDelegates.length; d++) {
      const delegate = props.formDelegates[d];
      if (typeof delegate === "string") {
        formContacts.push(delegate);
      } else {
        formContacts.push(delegate.eid);
      }
    }

    let tcIdData = props.formObjData.loadedTcID || 0;
    let tcReqIDData = props.formObjData.loadedTcReqID || 0;
    let descMMR = props.formDescriptionForMMR.replaceAll(" ", "");

    let rqstBody = {
      title: dataFromDom.title,
      url: dataFromDom.url,
      description:props.formDescription,
      descriptionmmr: descMMR,
      aid: dataFromDom.refiners,
      keywords: dataFromDom.greenKw.map((kw) => {
        return kw.toLowerCase();
      }),
      keywordsraw: dataFromDom.greenKw,
      invalidkeywords: dataFromDom.redKw.map((kw) => {
        return kw.toLowerCase();
      }),
      invalidkeywordsraw: dataFromDom.redKw,
      contacts: formContacts,
      comments: props.formObjData.arrComments,
      lastmodificationdate: currentUTCDate,
      creationdate: props.formObjData.creationdate || currentUTCDate,
      history: props.formObjData.history,
      expirationdate: null,
      status: status,
      submittedbyenterpriseid:
        props.formObjData.submittedbyenterpriseid ||
        localStorage.getItem("searchtools.eid"),
      submittedbypeoplekey:
        props.formObjData.submittedbypeoplekey ||
        parseInt(localStorage.getItem("searchtools.pk")),
      country: dataFromDom.countries,
      organization: [],
      tcid: tcIdData,
      streampostid: null,
      tcrequestid: tcReqIDData,
      template: "custom"
    };
    rqstBody.history.push({
      status: status,
      user: localStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });

    let request = {
      action: action,
      status: action === "Implement" ? "Implemented" : status,
      reqType: null,
      requestId: props.idFromUrl
    };

    if (!props.idFromUrl) {
      rqstBody.comments = PostCommentsInTheTracker(request).concat(
        rqstBody.comments
      );
    } else if (request.action === "Save Changes") {
      if (formHasChanges()) {
        rqstBody.comments = PostCommentsInTheTracker(request).concat(
          rqstBody.comments
        );
      } else {
        let user = localStorage.getItem("searchtools.eid");
        if (props.formObjData.comments !== "") {
          rqstBody.comments.unshift(
            CommentTracker.SaveComment(props.formObjData.comments, user)
          );
        }
      }
    } else {
      rqstBody.comments = PostCommentsInTheTracker(request).concat(
        rqstBody.comments
      );
    }

    return rqstBody;
  };

  const PostCommentsInTheTracker = (request) => {
    let user = localStorage.getItem("searchtools.eid");
    let comments = [];
    let delegatesComments = [];
    request.user = user;

    comments = CommentTracker.PostComments(request);

    if (props.idFromUrl) {
      let delegatesHaveChanged =
        JSON.stringify(props.formObjData.loadeddelegates) !==
        JSON.stringify(props.formDelegates);

      if (props.formDelegates.length > 0 && delegatesHaveChanged) {
        let delegatesInfo = {
          originalDelegates: props.formObjData.loadeddelegates,
          newDelegates: props.formDelegates,
          user: request.user,
          formType: "Topic Card"
        };
        delegatesComments = CommentTracker.PostDelegatesComments(delegatesInfo);
        comments.unshift(delegatesComments);
      }
    }

    if (props.formObjData.comments !== "") {
      comments.unshift(
        CommentTracker.SaveComment(props.formObjData.comments, user)
      );
    }
    return comments;
  };

  const submitTopicCard = async (status, event) => {
    let action = event.target.innerHTML;
    event.preventDefault();

    props.setIsLoading(true);
    let currentUTCDate = new Date().toISOString();
    let body = [];

    let rqstBody = BuildRequestJson(status, action);

    // //Custom Topic Card Request Status History
    rqstBody.history.push({
      status: props.formObjData.status,
      user: localStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });

    let previewContent = await props.tcService.GenerateHtmlContent(rqstBody);
    let descMMR = props.formDescriptionForMMR.replaceAll(" ", "");

    let tcBody = {};
    let listOfDelegates = [];
    for (let i = 0; i < rqstBody.contacts.length; i++) {
      const del = rqstBody.contacts[i];
      listOfDelegates.push({ eid: del, userid: 0 });
    }
    tcBody = {
      sthccontent: "",
      sthcsearchterms: rqstBody.keywords,
      sthcAID: rqstBody.aid,
      cleanstatus: "Active",
      title: rqstBody.title,
      cleanurl: rqstBody.url,
      cleancountry: [...rqstBody.country],
      cleandescription: rqstBody.description,
      cleandescriptionmmr: descMMR,
      delegates: listOfDelegates,
      cleankeywords: rqstBody.keywords,
      cleankeywordsraw: rqstBody.keywordsraw,
      cleaninvalidkeywords: rqstBody.invalidkeywords,
      cleaninvalidkeywordsraw: rqstBody.invalidkeywordsraw,
      creationdate: rqstBody.creationdate,
      template: rqstBody.template
    };

    try {
      tcBody.sthccontent = JSON.parse(previewContent.data);
    } catch (error) {
      tcBody.sthccontent = previewContent.data;
    }

    body.push(tcBody);
    body.push(rqstBody);
    let r = {};

    try {
      r = await props.tcService.SaveTopicCard(
        body,
        props.formObjData.loadedTcReqID,
        props.formObjData.loadedTcID
      );

      setModalMessage(
        "Custom Topic Card request ID # " +
          r.data._id +
          " has been saved and implemented. You may verify its implementation on Search."
      );
      props.setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    } catch (error) {
      setModalMessage("An error has been encountered. Please try again later.");
      props.setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    }
  };

  const SaveChanges = async (action, event) => {
    event.preventDefault();
    props.setIsLoading(true);
    let currentUTCDate = new Date().toISOString();
    let body = [];
    let rqstBody = BuildRequestJson(props.formObjData.status, action);

    //Custom Topic Card Request Status History
    rqstBody.history.push({
      status: rqstBody.status,
      user: sessionStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });

    body.push({});
    body.push(rqstBody);

    try {
      let r = await props.tcService.SaveTopicCard(
        body,
        props.formObjData.loadedTcReqID,
        props.formObjData.loadedTcID
      );

      let msg =
        "Custom Topic Card request ID # " + r.data._id + " was updated.";
      setModalMessage(msg);
      props.setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    } catch (error) {
      setModalMessage("An error has been encountered. Please try again later.");
      props.setIsLoading(false);
      setOpenRequestModal(!openRequestModal);
    }
  };

  // called by the modal and the cancel/remove buttons
  const RemoveMethod = async (status, action) => {
    let currentUTCDate = new Date().toISOString();
    let tcRequest = {};
    let body = [];
    let dataFromDom = getDataFromDom();
    let user = localStorage.getItem("searchtools.eid");

    props.setIsLoading(true);
    let oldReqData = props.originalReqData.tcReqData;
    //Topic Card Request Status History
    props.formObjData.history.push({
      status: status,
      user: sessionStorage.getItem("searchtools.eid"),
      dateUTC: currentUTCDate
    });

    //User Comment added when the Request is populated
    if (dataFromDom.comment && dataFromDom.comment.trim().length > 0) {
      props.formObjData.arrComments.unshift({
        comment: dataFromDom.comment.trim(),
        user: user,
        dateUTC: currentUTCDate
      });
    }

    tcRequest.doc = {};
    tcRequest.doc.title = oldReqData.title;
    tcRequest.doc.tcrequestid = oldReqData.tcrequestid;
    tcRequest.doc.lastmodificationdate = currentUTCDate;
    tcRequest.doc.expirationdate = currentUTCDate;
    tcRequest.doc.comments = props.formObjData.arrComments;
    tcRequest.doc.history = oldReqData.history;
    tcRequest.doc.yammerthreadid = oldReqData.yammerthreadid;
    tcRequest.doc.contacts = oldReqData.selectedeids;
    tcRequest.doc.submittedbyenterpriseid = oldReqData.submittedbyenterpriseid;
    tcRequest.doc.status = status;

    let request = {
      action: action,
      status: status,
      reqType: null,
      requestId: props.idFromUrl
    };

    if (!props.idFromUrl) {
      tcRequest.doc.comments = PostCommentsInTheTracker(request);
    } else {
      tcRequest.doc.comments = PostCommentsInTheTracker(request).concat(
        tcRequest.doc.comments
      );
    }

    if (status === "Inactive") {
      let tc = {
        doc: {
          cleanstatus: "Inactive"
        }
      };
      body.push(tc);
    } else {
      let tc = {
        doc: {}
      };
      body.push(tc);
    }

    body.push(tcRequest);

    let r = await props.tcService.UpdateTopicCard(
      body,
      props.formObjData.loadedTcReqID,
      props.formObjData.loadedTcID
    );

    let modalMessage = "";
    if (status === "Inactive") {
      modalMessage = "Custom Topic Card has been deleted.";
    } else {
      modalMessage =
        "Custom Topic Card" +
        " request ID # " +
        r.data._id +
        " was " +
        status.toLowerCase();
    }

    setModalMessage(modalMessage);
    setOpenRequestModal(!openRequestModal);
    props.setIsLoading(false);
  };

  const RemoveTc = (pStatus, event) => {
    if (event !== null) {
      event.preventDefault();
    }
    if (pStatus === "Inactive") {
      setModalMessage("Do you want to remove this Custom Topic Card? ");
      setOpenCancelRequestModal(!openCancelRequestModal);
    } else {
      RemoveMethod(pStatus, event.target.innerHTML);
    }
  };

  const closeTheModal = () => {
    setOpenCancelRequestModal(!openCancelRequestModal);
  };

  const onClickCancelRequestModal = () => {
    closeTheModal();
    RemoveMethod("Inactive", "Remove Topic Card");
  };

  const openCtcPreview = () => {
    props.openCtcPreview();
  };
  const showPreview = async (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    let previewBody = await BuildRequestJson("New", "Request Topic Card");
    let tcpreview = await props.tcService.GenerateHtmlContent(
      previewBody
    );

    try {
      props.setPreviewContent(tcpreview.data);
    } catch (error) {
      props.setPreviewContent(tcpreview.data);
    }
    props.setIsLoading(false);
    openCtcPreview();
  };

  const htmlButtons = {
    previewBtn: (
      <button
        className="btn btn-lg btn-secondary tc-btn-form-styles"
        data-testid="ctc-form-button"
        id="tc-preview-button"
        disabled={!props.disableButton}
        onClick={showPreview}
      >
        Preview
      </button>
    ),
    rejectBtn: (
      <button
        className={`btn btn-lg btn-outline-danger red-form-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-reject-button"
        onClick={(event) => {
          RemoveTc("Not Approved", event);
        }}
        disabled={!props.disableButton}
      >
        Reject
      </button>
    ),
    removeRequestBtn: (
      <button
        className={`btn btn-lg btn-outline-danger red-form-btn tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-remove-request-button"
        onClick={(event) => {
          RemoveTc("Inactive", event);
        }}
        disabled={!props.disableButton}
      >
        Remove
      </button>
    ),
    saveChangesBtn: (
      <button
        className={`btn btn-lg btn-outline-${primaryBtn} tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="ctc-save-changes-btn"
        onClick={(event) => {
          SaveChanges("Save Changes", event);
        }}
        disabled={!props.disableButton}
      >
        Save Changes
      </button>
    ),
    implementBtn: (
      <button
        className={`btn btn-lg btn-primary btn-${primaryBtn} tc-btn-form-styles`}
        data-testid="tc-form-button"
        id="tc-implement-btn"
        onClick={(event) => submitTopicCard("Implemented", event)}
        disabled={!props.disableButton}
      >
        Implement
      </button>
    )
  };

  let showRemoveButton = false;
  let showRejectButton = false;
  let showImplementButton = false;
  let currEid = localStorage.getItem("searchtools.eid");
  let isDelegate = false;

  try {
    isDelegate = props.formObjData.loadeddelegates.includes(currEid);
  } catch (error) {}

  if (props.formObjData.status === "Implemented") {
    showRemoveButton = props.isAdmin;
  }

  if (
    props.formObjData.status === "New" &&
    parseInt(props.formObjData.loadedTcReqID) > 0
  ) {
    showRejectButton = props.isAdmin;
  }

  if (!props.isAdmin && isDelegate) {
    showImplementButton = false;
  } else if (props.isAdmin) {
    showImplementButton = true;
  }

  return (
    <div className="row">
      {openRequestModal ? (
        <ModalCustom
          darkMode={props.isDarkMode}
          onClose={onClickRequestModal}
          modal_msg={modalMessage}
          btn_1_class={
            props.isDarkMode
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      ) : (
        <div></div>
      )}
      {openCancelRequestModal ? (
        <ModalCustom
          darkMode={props.isDarkMode}
          onClose={onClickCancelRequestModal}
          modal_msg={modalMessage}
          btn_1_class={
            props.isDarkMode
              ? "btn btn-dark-mode-purple btn-adjust-content-cancel"
              : "btn btn-light-mode-purple btn-adjust-content-cancel"
          }
          btn_2_class={
            props.isDarkMode
              ? "btn btn-dark-mode-purple btn-adjust-content-confirm-remove"
              : "btn btn-light-mode-purple btn-adjust-content-confirm-remove"
          }
          modalSize=""
          noHeader={true}
          secondOption={true}
          firstOptText={"Remove Custom Topic Card"}
          onClick={onClickCancelRequestModal}
          secondOptText={"Cancel"}
          onClickSecondOpt={closeTheModal}
        />
      ) : (
        <div></div>
      )}
      <div
        id="ctc-form-buttons"
        data-testid="testid-ctc-form-buttons"
        className={props.ctc_form_buttons_div}
      >
        {showRejectButton ? htmlButtons.rejectBtn : <></>}
        {showRemoveButton ? htmlButtons.removeRequestBtn : <></>}
        {htmlButtons.previewBtn}
        {htmlButtons.saveChangesBtn}
        {showImplementButton ? htmlButtons.implementBtn : <></>}
      </div>
    </div>
  );
};

export default CTCButtons;
