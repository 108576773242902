

const UrlSuggestions = ({urlSuggestions, selectSuggestion, suggestionKeydown})=>{
    return(<ul id="url-suggestions-list" className="url-suggestions-selector">
    {urlSuggestions.requests.map((bb) => {
      return (
        <li
          role="presentation"
          className="url-suggestion-list-class"
          key={bb._source.bbid}
          tabIndex={-1}
        >
          <a
            value={bb._source.cleanurl}
            role="menuitem"
            key={bb._source.bbid}
            id={bb._source.bbid}
            onClick={(e)=>{selectSuggestion(e)}}
            onKeyDown={(e)=>{suggestionKeydown(e)}}
            href="/#"
            target="_self"
            className="dropdown-item"
          >
            {bb._source.cleanurl}
          </a>
        </li>
      );
    })}
  </ul>);
}


export default UrlSuggestions;