import React from 'react'

export default function GenAiLoadingSpinner() {
    return (
        <div
            className="d-flex justify-content-center genai-loading-spinner main-form-loading-spinner"
        >
            <div className="spinner-border text-light-mode-purple" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};