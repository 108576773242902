



import { useState, useEffect } from "react";
import FieldLabelAndIcon from "../FieldLabelAndIcon";


const LocationOrRefinerPageField = ({reqType,
    inputType, 
    formName,
    field,
    fieldNameForLabel,
    isLocationField,
    updateFormState,
    onLocationOrAidChange,
    readOnlyMode,
    formPillsStyle,
    formPillsBtnFontWeight,
    currFormData,
    displayGrayPills,
    tooltipText}) => {

  const [dropdownSelectOptions, setDropdownSelectOptions] = useState([]);
  const [cssState, setCssState] = useState(" light-mode-purple ");
  const inputID = `${reqType}-${inputType}-input-field`;

  const cssFieldName = isLocationField ? "country" : "refiner"; 
 
  const locAriaText = `Select a target location(s) for ${formName}`;
  const refinerAriaText = `Refiner page dropdown. Press Enter to select Search page (refiner page) to display your ${formName}`
  const ariaLabelText = isLocationField ? locAriaText : refinerAriaText;
  
  const locDrpDefaultVal = `Select target location(s) for ${formName}`;
  const refDrpDefaultVal = `Select Search Refiner where to display the ${formName}`;
  const dropdownDefaultValue = isLocationField ? locDrpDefaultVal : refDrpDefaultVal;

  const dataTestIDOption = `${cssFieldName}-options`;
  const selectedOptionsListId = isLocationField ? "selected-countries" : "selected-refiner-pages";


  useEffect(() => {
    if (sessionStorage.getItem("searchtools.config")) {
      let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));
      const locOrRefOption = isLocationField ? "location" : "refiner-page";
        
      let cfgElement = null;
      let objSelector = "";
      let cfgArr = [];

      if(reqType === "BB"){
        objSelector = "bestbet";
        cfgArr = cfg[0];
      }else{
        // every other form uses this tc config options
        objSelector = "topiccard";
        cfgArr = cfg[1];
      }

      if(isLocationField){
        cfgElement = cfgArr[objSelector].countries;
      }else{
        cfgElement = cfgArr[objSelector].application;
      }

      const optionsList = cfgElement.map((option) => {
          return (
          <option
              id={`${reqType}-form-dropdown-${locOrRefOption}-option-${option.value}`}
              key={option.value}
              value={option.value}
          >
              {option.text}
          </option>
          );
      });

      setDropdownSelectOptions(optionsList);
    }
  }, []);

  const resetDropdownToDefault = () => {
    document.getElementById(`${reqType}-${cssFieldName}-select-id`).selectedIndex = 0;
  };

  const AddCountries = (event) => {
    if (currFormData.indexOf(event.target.value) === -1) {
      if (event.target.value === "Global") {
        let countriesArr = [...currFormData];
        countriesArr = ["Global"];
        setCssState(" is-valid ");
        updateFormState(field, countriesArr, true);
        onLocationOrAidChange();
        resetDropdownToDefault();
      } else {
        let countriesArr = [...currFormData];
        if (countriesArr.indexOf("Global") === -1) {
          countriesArr.push(event.target.value);
          resetDropdownToDefault();
          setCssState(" is-valid ");
          updateFormState(field, countriesArr, true);
          onLocationOrAidChange();
        } else {
          resetDropdownToDefault();
          return;
        }
      }
    } else if (currFormData.indexOf(event.target.value) > -1) {
      resetDropdownToDefault();
      return;
    }
  };

  const AddRefiner = (event) => {
    if (currFormData.indexOf(event.target.value) === -1) {
      let refinerArr = [...currFormData];
      refinerArr.push(event.target.value);
      resetDropdownToDefault();
      setCssState(" is-valid ");
      updateFormState(field, refinerArr, true);
      onLocationOrAidChange();
    } else if (currFormData.indexOf(event.target.value) > -1) {
      let refinerArr = [...currFormData];
      if (refinerArr.length === 0) {
        setCssState(" light-mode-purple ");
        updateFormState(field, [], false);
        resetDropdownToDefault();
        return;
      }
      resetDropdownToDefault();
      return;
    }
  };

  const AddItemToList = (event) =>{
    isLocationField ? AddCountries(event) : AddRefiner(event);
  }

  const removeItemFromList = (event) => {
    let currSelectedOptionsArr = [...currFormData];
    currSelectedOptionsArr = currSelectedOptionsArr.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    if (currSelectedOptionsArr.length === 0) {
        setCssState(null);
        updateFormState(field, [], false);
    }else{
        updateFormState(field, currSelectedOptionsArr, true);
    }
    
    onLocationOrAidChange();
  };


  const optionsSelected = currFormData.map((opt) => {
    return (
      <div
        id={`${cssFieldName}-on-list`}
        data-testid={`testid-${cssFieldName}-on-list`}
        aria-label={`${cssFieldName}-on-list`}
        key={opt + "_div"}
        className={"btn-group " + formPillsStyle}
        role="group"
      >
        <button
          id={`${cssFieldName}-name-on-list`}
          data-testid={`testid-${cssFieldName}-name-on-list`}
          aria-label={`${cssFieldName} ${opt}`}
          key={opt + "_btn_opt_key"}
          className={`btn ${displayGrayPills ? " btn-gray-class " : " btn-success "}` + formPillsBtnFontWeight}
          type="button"
        >
          {opt}
        </button>
        {readOnlyMode ? (
          <></>
        ) : (
          <button
            data-testid="cross-pill"
            id={opt + "_remove_btn"}
            key={opt + "_remove_btn_key"}
            value={opt}
            className={`btn ${displayGrayPills ? " btn-gray-class " : " btn-success "}` + formPillsBtnFontWeight }
            type="button"
            aria-label={"remove " + opt + " from list"}
            onClick={removeItemFromList}
          >
            x
          </button>
        )}
      </div>
    );
  });

  return (
    <div className={`${reqType}-${inputType}-input-field-div dropdown-field-div`}>
      <FieldLabelAndIcon
        reqType={reqType}
        inputType={inputType}
        inputID={`${inputID}-label`}
        isRequired={true}
        labelText={fieldNameForLabel}
        tooltipText={tooltipText}
      />
      <select
        id={`${reqType}-${cssFieldName}-select-id`}
        data-testid={`testid-${reqType}-${cssFieldName}-select-id`}
        className={"form-control form-dropdown-styles" + cssState}
        aria-label={ariaLabelText}
        onChange={AddItemToList}
        defaultValue={dropdownDefaultValue}
        disabled={readOnlyMode}
      >
        <option disabled={true} data-testid={`${dataTestIDOption}`}>
          {dropdownDefaultValue}
        </option>
        {dropdownSelectOptions}
      </select>

      <div
        id={`${reqType}-${selectedOptionsListId}`}
        data-testid={`testid-${reqType}-${selectedOptionsListId}`}
        className={"selected-location-or-refiners-div"}
      >
        {optionsSelected}
      </div>
    </div>
  );
};

export default LocationOrRefinerPageField;
