import ModalCustom from "../../../Modal/ModalCustom";
import successLight from "../../../../assets/images/success-light.svg";
import successDark from "../../../../assets/images/success-dark.svg";
import "./genAiModal&Button.css";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../../../ThemeContext.jsx";
import "./twinkle.css";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations.js";
import starone from "../../../../assets/images/startone.svg";
import startwo from "../../../../assets/images/startwo.svg";
import starthree from "../../../../assets/images/starthree.svg";

const GenAiModal = (props) => {
  const [savedDescription, setSavedDescription] = useState(false);
  const [kwModalMsg, setKwModalMsg] = useState("");
  const [showKwModal, setShowKwModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(true);
  const [theme] = useContext(ThemeContext);

  const [dotAnimation, setDotAnimation] = useState("");
  const counter = useRef(0);

  useEffect(() => {
    const animate = setInterval(() => {
      if (counter.current < 4) {
        setDotAnimation((prev) => prev + ".");
        counter.current++;
      }
      if (counter.current >= 4) {
        setDotAnimation("");
        counter.current = 0;
      }
    }, 500);

    return () => clearInterval(animate);
  }, []);


  useEffect(() => {
    if (savedDescription) {
      setTimeout(() => {
        props.closeModal();
      }, 3000);
    }
  }, [savedDescription]);

  const lastGeneratedKwString = ()=>{
    let kwString = "";
    try {
      for (let i = 0; i < props.lastGeneratedKeywords.length; i++) {
        const kw = props.lastGeneratedKeywords[i];
        kwString+= i === 0 ? kw : ", "+kw;
      }
    } catch (error) {}

    return kwString;
  }

  const handleAccept = (e) => {
    let isValid = false;
    let parsedDesc = props.lastGeneratedDescription.replaceAll("##","");
    parsedDesc = parsedDesc.trim();
    switch (props.validationType) {
      case "BB_Validations":
        isValid = props.BB_Validations.validateDescription(
          parsedDesc
        ).isValid;
        break;
      case "TopicCardValidations":
        isValid = TopicCardValidations.validateDescription(
          parsedDesc
        ).isValid;
        break;
      default:
        console.error("Unknown validation type");
    }

    let valRes = {isValid : isValid}
    if(props.selectedGenAiOption === "description"){
      props.replaceDescription(
        "description",
        parsedDesc,
        valRes
      );
      props.updateDescUI();
      setSavedDescription(true);
    }else{
      
      let currLocationsSelected = null;
      let currRefinersSelected = null;

      if(props.currFormData.countries.hasOwnProperty("value")){
          currLocationsSelected = [...props.currFormData.countries.value];
      }
      if(props.currFormData.refinerPages.hasOwnProperty("value")){
          currRefinersSelected = [...props.currFormData.refinerPages.value];
      }

      if (
        currRefinersSelected.length === 0 ||
        currLocationsSelected.length === 0
      ) {
        setShowDataModal(false);
        setKwModalMsg("Please select a Location and a Refiner first.");
        setShowKwModal(true);
        return;
      }

      if(props.currFormData.keywordsObj.keywords.value.length === 20){
        setShowDataModal(false);
        setKwModalMsg("Only 20 keywords are allowed");
        setShowKwModal(true);
        return;
      }

      let keywordsToAdd = [];
      let keywordsToAddSet = new Set();

      let currLength = props.currFormData.keywordsObj.keywords.value.length;
      let toAddLength = props.lastGeneratedKeywords.length;

      if(currLength + toAddLength > 20){
        setShowDataModal(false);
        setKwModalMsg("Only 20 keywords are allowed");
        setShowKwModal(true);
        return;
      }
      
      for (let i = 0; i < props.lastGeneratedKeywords.length; i++) {
        let kw = props.lastGeneratedKeywords[i];
        kw = kw.replaceAll(".","");
        kw = kw.trim();
        keywordsToAddSet.add(kw);
      }

      for (let i = 0; i < props.currFormData.keywordsObj.keywords.value.length; i++) {
        const kw = props.currFormData.keywordsObj.keywords.value[i];
        keywordsToAddSet.add(kw);
      }
      
      keywordsToAdd = Array.from(keywordsToAddSet); 
      let valRes = {isValid : true}
      props.addNewKeywords("keywords",keywordsToAdd,valRes,true);
      setSavedDescription(true);
    }


    try {
      let divHolder = document.querySelector(".kw-div-elements-holder-selector");
      let divUrlAndGenAi = document.querySelector(".div-url-and-gen-ai-container-selector");
      
      divHolder.classList.remove("show-genai-options-position");
      divUrlAndGenAi.classList.remove("div-url-and-gen-ai-container");
      
    } catch (error) {}

    if(props.requestType === "BB"){
      props.setShowGenAiOptions(false);
    }
  };


  const handleCancel = (e) => {
    e.preventDefault();
    props.closeModal();
    props.changeIsGenAiLoadingData(false);
    console.log("Request canceled by user.");
  };



  const onClickModalHandler = () => {
    setShowKwModal(false);
    setShowDataModal(true);
  };

  
  const modalBody = (
    <>
      <h2 id={`${props.selectedGenAiOption}-title`} aria-live="polite" aria-label={`${props.selectedGenAiOption}`}>
        <b>{props.selectedGenAiOption === "description" ? "Description" : "Keywords"}</b>
      </h2>
      <p
        id={`generated-${props.selectedGenAiOption}`}
        data-testid={`gen-${props.selectedGenAiOption}`}
        aria-live="polite"
        aria-label={props.selectedGenAiOption === "description" ? props.lastGeneratedDescription : lastGeneratedKwString()}
      >
        {props.selectedGenAiOption === "description" ? props.lastGeneratedDescription : lastGeneratedKwString()}
      </p>
      <p
        className="disclaimer"
        id="ai-disclaimer"
        aria-live="polite"
        aria-label="Response generated by AI. Verify accuracy and privacy restrictions before use"
      >
        Response generated by AI. Verify accuracy and privacy restrictions
        before use.
      </p>
    </>
  );

  const successBody = (
    <div className="success-body" style={{ marginBottom: 10 }}>
      <img
        src={theme === "dark" ? successDark : successLight}
        className="success-icon"
        alt="Check mark"
        id="success-icon"
        aria-labelledby="success-title"
      />
      <p
        style={{ fontWeight: "bold", fontSize: 28, marginBottom: 5 }}
        id="success-title"
      >
        Data generated successfully!
      </p>
      <p id="success-message" aria-live="polite">
        Your Gen AI data is ready. Explore and analyze the results.
      </p>
      <button
        id="success-ok-button"
        className="modal-button-second"
        style={{ backgroundColor: "var(--light-mode-secondary-button-color)" }}
        onClick={props.closeModal}
        aria-label="Close the modal and view results"
      >
        OK
      </button>
    </div>
  );

  const TwinkleImage = (src, top, left, delay) => {
    return (
      <img
        src={src}
        style={{
          position: "absolute",
          top: top,
          left: left,
          width: "36px",
          height: "34px",
          visibility: "visible",
          animationDelay: delay
        }}
        className="twinkle"
        alt="Twinkling star"
      />
    );
  };

  const generatingBody = (
    <div
      style={{
        padding: "25px 175px",
        borderRadius: "4px 16px 16px 16px",
        display: "grid"
      }}
    >
      <div
        className="starwrapper"
        style={{
          position: "relative",
          marginLeft: "42%",
          width: 50,
          height: 50
        }}
      >
        {TwinkleImage(startwo, "-14px", "13px", "0s")}
        {TwinkleImage(starone, "7px", "-2px", "0.5s")}
        {TwinkleImage(starthree, "18px", "21px", "1s")}
      </div>
      <div
        style={{
          marginTop: 25,
          width: "-webkit-fill-available",
          marginBottom: 15
        }}
      >
        <p
          className={theme + "-gradient"}
          id={props.selectedGenAiOption === "description" ? "generating-description" : "generating-keywords"}
          style={{
            fontWeight: 600,
            fontSize: "18px",
            marginLeft: "25%",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text"
          }}
          aria-live="polite"
        >
          {props.selectedGenAiOption === "description" ? "Generating Description" : "Extracting Keywords"}{dotAnimation}
        </p>
      </div>
      <button
        id="cancel-button"
        className={`modal-button-second ${theme === "dark" && "pink"}`}
        style={{ marginLeft: "38%" }}
        onClick={handleCancel}
        aria-label="Cancel the generation process"
      >
        Cancel
      </button>
    </div>
  );

  return (
    <>
      {showKwModal ? (
          <ModalCustom
            onClick={onClickModalHandler}
            onClose={onClickModalHandler}
            modal_title={"Info"}
            modal_msg={kwModalMsg}
            secondOption={false}
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
          />
        ) : (
          <div></div>
      )}
      { showDataModal &&  (
        <ModalCustom
          xClose={true}
          onClose={props.closeModal}
          modal_msg={savedDescription ? successBody : props.isGenAiLoadingData? generatingBody : modalBody}
          modal_title="Gen AI on Search Tools"
          firstOptText="Accept"
          secondOption={false}
          modalSize={"description-modal " + (savedDescription ? "success" : "")}
          firstBtnAction={handleAccept}
          noFooter={savedDescription}
          zindex={11}
          firstOpAriaLabel="Accept the generated description"
          btn_1_class={"modal-button-second"}
        />
      )}
    </>
  );
};

export default GenAiModal;
