import React from 'react'

export default function GenAiLoadingSpinnerTC() {
    return (
        <div
            className="ai-loading-tc-url"
        >
            <div className="spinner-border text-light-mode-purple" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};