
import FieldLabelAndIcon from "../FieldLabelAndIcon";

const KeywordSynonyms = ({reqType,
    formPillsStyle,
    formPillsBtnFontWeight,
    clipboardHandler,
    addKeyword,
    synonymsListArr})=>{


    const inputID = `${reqType}-form-kw-synonyms-list`;
    const labelText = "Related keyword suggestions";
    const tooltipText = "Suggestion to the entered keywords."

    const renderSynonymsList = ()=>{

        if(synonymsListArr === null) return;
        let allbtnList = [];
        
        for (let i = 0; i < synonymsListArr.length; i++) {
          const opt = synonymsListArr[i];
          let idStatusClass = " green-kw-class ";
          let btnStatusClass = " btn-success ";
          
          const nBtn = <div
                        id="kw-synonym-on-list"
                        data-testid="testid-kw-synonym-on-list"
                        aria-label="kw-synonym-on-list"
                        key={opt + "_div"}
                        className={"btn-group " + formPillsStyle}
                        role="group"
                      >
                        <button
                          aria-label={"kw-synonym " + opt}
                          data-testid="kw-synonym-pill"
                          key={opt + "_btn_opt_key"}
                          className={
                            "btn " + btnStatusClass + formPillsBtnFontWeight + idStatusClass
                          }
                          type="button"
                          onClick={clipboardHandler}
                        >
                          {opt}
                        </button>
                        <button
                        id={opt + "_remove_btn"}
                        key={opt + "_remove_btn_key"}
                        value={opt}
                        className={"btn " + btnStatusClass + formPillsBtnFontWeight}
                        type="button"
                        aria-label={"add " + opt + " synonym from list"}
                        onClick={(e)=>{addKeyword(e,true)}}
                        data-bs-toggle="synonym-suggestion-tooltip"
                        data-bs-placement="top"
                        data-bs-title="Add as keyword"
                        >
                        +
                        </button>
                      </div>
          allbtnList.push(nBtn);
        }

        return allbtnList;
      }

    return(
        <div id={inputID} className={`kw-synonyms-list-selector`}>
            <div style={{display : "flex"}}>
                <FieldLabelAndIcon
                    reqType={reqType}
                    inputType={"keyword-input"}
                    inputID={`${inputID}-label`}
                    isRequired={false}
                    labelText={labelText}
                    tooltipText={tooltipText}
                />
                <div
                    className="spinner-synonyms spinner-border spinner-border-sm synonyms-kw-spinner-selector"
                    role="status"
                    style={{marginLeft : "0.5em"}}
                ></div>
            </div>
            <div id="kw-suggestion-list-box" className={`card form-control kw-synonyms-list kw-synonyms-list-box-selector`}> 
                {renderSynonymsList()}
            </div>
        </div>
    );
}

export default KeywordSynonyms;