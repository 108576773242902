

import React, { useEffect, useState } from "react";
import FieldLabelAndIcon from "./FieldLabelAndIcon";

const TitleField = ({inputType, 
    labelText,
    reqType,
    placeholderText,
    ariaLabelText,
    tooltipText,
    isRequired,
    isDisabledProp,
    validateField,
    updateFormState}) =>{

    const inputID = `${reqType}-${inputType}-input-field`;
    const errorMsgBoxID = `${reqType}-${inputType}-error-msg-box`;

    const [timer, setTimer] = useState(null)



    // methods
    const updateUIElements = (_validationResult, _userInput) =>{
        let domField = null;
        let errorMessageDiv = null;

        domField = document.getElementById(inputID);
        if(_validationResult.isValid){
            
            try {
                errorMessageDiv = document.getElementById(errorMsgBoxID);
                errorMessageDiv.textContent = "";
                errorMessageDiv.hidden = true;
                domField.classList.remove("is-invalid");
            } catch (error) { console.log("error 1 on title field."); }
            
            domField.classList.add("is-valid");

        }else if(!_validationResult.isValid){
            
            try {
                domField.classList.remove("is-valid");
            } catch (error) { console.log("error 2 on title field."); }
            
            if(_userInput.length !== 0){
                domField.classList.add("is-invalid");
                //show error msg if any
                try {
                    if(_validationResult.errorMsg.length > 0){
                        errorMessageDiv = document.getElementById(errorMsgBoxID);
                        errorMessageDiv.textContent = _validationResult.errorMsg;
                        errorMessageDiv.hidden = false;
                    }
                } catch (error) {
                    console.log("error 3 on title field.");
                }
            }else if(_userInput.length === 0){
                try {
                    domField.classList.remove("is-invalid");
                    if(_validationResult.errorMsg.length > 0){
                        errorMessageDiv = document.getElementById(errorMsgBoxID);
                        errorMessageDiv.textContent = "";
                        errorMessageDiv.hidden = true;
                    }
                } catch (error) {
                    console.log("error 4 on title field.");
                }
            }
        }
    }


    const processUserInput = async(_userInput) =>{
        try {
            let validationResult = await validateField(_userInput);
            updateUIElements(validationResult, _userInput);
            updateFormState(inputType, _userInput, validationResult);
        } catch (error) {
            console.log("error processUserInput title field.");
        }
    }

    const getFieldValue = (event) => {
        const userInput = event.target.value;
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            processUserInput(userInput);
        }, 900)

        setTimer(newTimer);
    };

    return (
        <div id={`${reqType}-${inputType}-input-field-div`}>
            <FieldLabelAndIcon
                reqType={reqType}
                inputType={inputType}
                inputID={`${inputID}-label`}
                isRequired={isRequired}
                labelText={labelText}
                tooltipText={tooltipText}
            />
            <input 
                id={inputID}
                type="text"
                autoComplete="off"
                className={`form-control `}
                data-testid={`${reqType}-${inputType}-text-input-field`}
                placeholder={placeholderText}
                aria-label={ariaLabelText}
                onChange={getFieldValue}
                disabled={isDisabledProp}
            />
            <div id={errorMsgBoxID} className="invalid-field-div" ></div>
        </div>
    );
}



export default TitleField;